import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './controllers';
import store from './store';
import './main.css'; // Import the styles here

axios.defaults.xsrfCookieName = 'csrf-token';
axios.defaults.xsrfHeaderName = 'X-csrf-token';

const app = createApp(App);
app.use(router).use(store)
app.mount('#app');
