<template>
  <!-- This is a custom tab button -->
    <div class="TabContainer">
        <button class="TabBackground" :class="displayClasses" @mouseover="handleMouseOver" @mouseout="handleMouseOut" @keydown="handleKeyDown" @click="handleClick"
         @blur="handleBlur" @focus="handleFocus" :aria-disabled=isDisabled :disabled=isDisabled role="button" :aria-labelledby="labelId">
            <div class="TabText" :class="displayClasses" :id="labelId"> {{ label }}
            </div>
            <div class="TabIndicator" :class="displayClasses"></div>
          </button>
    </div>
</template>
  
<script>
  export default {
    name: 'tab-button',
    emits: ['tabClick'],
    props: {
      componentId: {
        type: String,
        required: true,
      },
      hasRightSibling: { //whether there is a step to the right
        type: Boolean,
        default: false,
      },
      isSelected: { //whether the step is currently selected; if active, it can't be disabled
        type: Boolean,
        default: false,
      },
      isDisabled: { //whether the step is currently disabled; if disabled, it can't be active
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: null,
      }
    },
    computed: {
      labelId() { //generate an ID for the button label for accessibility
        return `tab-${this.generateUniqueId()}`;
      },
      displayClasses() {
        if (this.isDisabled) {
          return 'disabled';
        } else if (this.isHoverState && !this.isSelected) {
          return 'hover';
        } else if (this.isHoverState && this.isSelected) {
          return 'hover-selected';
        } else if (this.isFocusState && !this.isSelected) {
          return 'focus';
        } else if (this.isFocusState && this.isSelected) {
          return 'focus-selected';
        } else if (this.isSelected) {
          return 'selected';
        } else {
          return 'normal';
        } 
      },
    },
    data() {
      return {
        isHoverState: false,
        isFocusState: false,
      };
    },
    methods: {
      handleMouseOver() { //when a user hovers in using a mouse
          this.isHoverState = true;
      },
      handleMouseOut() { //when a user hovers out using a mouse
          this.isHoverState = false;
      },
      handleKeyDown(event) { //When a user enters a key on the button
          if (this.isDisabled) {
            return;
          }
          if (event.key === 'Enter' || event.key === ' ') {
            this.handleClick();
          }
      },
      handleFocus() { //when a user tabs in using a keyboard
        this.isFocusState = true;
       },
      handleBlur() { //when a user tabs out using a keyboard
        this.isFocusState = false;
        this.isHoverState = false;
      },
      handleClick() {
        this.$emit('tabClick', this.componentId);
      },
      generateUniqueId() {
      // Logic to generate a unique ID based on timestamp
      return Date.now();
      },
    },
  };
</script>
<style scoped>

.TabContainer {
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: flex-start;
  align-items: flex-start;
  height: 2rem;
}

.TabBackground { 
  /* Custom tab button style */
    width: 8.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem .25rem;
    gap: 0.5rem;
    background-color: transparent;
    border: none;
}

.TabBackground.normal {
  cursor: pointer;
}
.TabBackground.hover {
  background-color: var(--color-background-primary);
  cursor: pointer;
}
.TabBackground.focus {
  background-color: var(--color-outline-hover);
  cursor: pointer;
  outline: none;
}
.TabBackground.hover-selected,
.TabBackground.selected {
  cursor: default;
}
.TabBackground.focus-selected {
  background-color: var(--color-outline-hover);
  cursor: default;
  outline: none;
}
.TabBackground.disabled {
  cursor: not-allowed;
}

.TabIndicator {
    height: 0.125rem; /* same as tab menu bottom border thickness */
    width: 8.5rem; /* same width as the Tab background */
    position: absolute;
    top: 2.125rem; /* same as the height */
    border-radius: var(--button-border-radius);
  }
  .TabIndicator.normal {
    background-color: var(--color-outline-normal);
  }

  .TabIndicator.disabled {
    background-color: transparent;
  }
  .TabIndicator.focus-selected,
  .TabIndicator.focus, 
  .TabIndicator.hover-selected,
  .TabIndicator.hover {
    background-color: var(--color-input-normal);
  }
  .TabIndicator.selected {
    background-color: var(--color-input-selected);
  }
  .TabText {
    font-size: var(--size-normal); 
    font-weight: var(--weight-normal); ; 
    line-height: var(--size-normal);
  }
 
  .TabText.normal {
    color: var(--color-text-description);
  }
  .TabText.disabled {
    color: var(--color-outline-disabled);
  }
  .TabText.focus,
  .TabText.hover {
    color: var(--color-input-normal);
  }
  .TabText.focus-selected,
  .TabText.hover-selected {
    color: var(--color-input-normal);
  }
  .TabText.selected {
    color: var(--color-input-selected);
  }

</style>