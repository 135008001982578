<template>
    <div class="Background">
        <div class="ContentContainer">
            <div class="EditorPanel" style="margin-left: 40px;">
                <!-- Progress Bar -->
                <div style="margin-top: 150px;">
                    <progress-bar :initialState=0 :totalDuration=5 :showProgressAnimation=showProgressAnimation
                    :isError=showProgressError :text=progressBarText />
                </div>
                <div style="margin-top: 20px;">
                    <button @click="showProgressBar(false)">Toggle Progress</button>
                </div>
                <!-- Stepper buttons -->
                <div class="Playground"
                    style="width: 480px; height: 32px; position: relative; display: flex; flex-direction: row; margin-top: 10px;">
                    <stepper-button :isComplete="true" :hasRightSibling="true" :isSelected="true" :isDisabled="false"
                        text="input" />
                    <stepper-button @click="showProgressBar(false)" :isComplete="true" :hasRightSibling="true"
                        :isSelected="false" :isDisabled="false" text="input2" />
                    <stepper-button :isComplete="false" :hasRightSibling="true" :isSelected="true" :isDisabled="false"
                        text="input3" />
                    <stepper-button :isComplete="false" :hasRightSibling="true" :isSelected="false" :isDisabled="false"
                        text="resume" />
                    <stepper-button :isComplete="false" :hasRightSibling="false" :isSelected="false" :isDisabled="true"
                        text="cover letter" />
                </div>
                <!-- Tabs -->
                <div class="Playground"
                    style="width: 480px; height: 60px; position: relative; display: flex; flex-direction: row; margin-top: 60px;">
                    <tab-button :hasRightSibling=true :isSelected=true label="Preview"></tab-button>
                    <tab-button :hasRightSibling=true :isSelected=false label="Match"></tab-button>
                    <tab-button :hasRightSibling=false :isSelected=false :isDisabled=true label="Trivia"></tab-button>
                </div>
                <!-- Radio Groups -->
                <div class="Playground"
                    style="width: 480px; height: 40px; position: relative; display: flex; flex-direction: row; margin-top: 20px; gap:20px;">

                        <input class="ThemedRadioButton" type="radio" id="1" value="1">
                        <label class="TextParagraph" for="option_job_url">Text 1</label>

                        <input class="ThemedRadioButton" type="radio" id="2" value="2" :disabled="true">
                        <label class="TextParagraph" for="option_job_url">Text 2</label>

                        <input class="ThemedRadioButton" type="radio" id="3" value="j3">
                        <label class="TextParagraph" for="option_job_url">Text 3</label>
                </div>
                <!-- End Radio Groups -->
                <!-- Custom Buttons -->
                <div class="Playground"
                    style="width: 480px; position: relative; display: flex; flex-direction: row; margin-top: 20px; gap:20px;">
                    <themed-button button-type="primary" label="LABEL 1" :isDisabled="false" />
                    <themed-button button-type="primary" label="LABEL 2" :isDisabled="true" />
                    <themed-button button-type="primary" label="LABEL 3" :isDisabled="false" />
                </div>
                <div class="Playground"
                    style="width: 480px; position: relative; display: flex; flex-direction: row; margin-top: 20px; gap:20px;">
                    <themed-button button-type="secondary" label="LABEL 4" :isDisabled="false" />
                    <themed-button button-type="secondary" label="Label 5" :isDisabled="true" />
                    <themed-button button-type="secondary" label="Label 6" :isDisabled="false" />
                </div>
                <div class="Playground" style="color: white; height: 2rem; width: 2rem;">
                    <themed-button button-type="primary" icon="ai" tooltip-text="Email long form text"/>
                </div>
                <!-- End Custom Buttons -->
                <!-- Custom Input -->
                <div class="Playground"
                    style="width: 480px; position: relative; display: flex; flex-direction: row; margin-top: 20px; gap:20px;">
                    <input class="ThemedInput" type="text">
                    <input class="ThemedInput" type="text" :disabled="true">
                </div>
                <!-- End Custom Input -->
                <!-- Icon Buttons -->
                <div class="Playground"
                    style="width: 480px; position: relative; display: flex; flex-direction: row; margin-top: 20px; gap:20px; color:blue">
                    <div style="width:20px; height: 20px; margin:5px 10px;">
                        <themed-button button-type="icon" icon="circle-down-arrow" tooltip-text="Email long form text" :toggle="true"/>
                    </div>
                    <div class="CardIconButton">
                        <themed-button button-type="icon" icon="circle-down-arrow" tooltip-text="Email long form text" />
                    </div>
                    <div style="width:20px; height: 20px; margin:5px 10px;">
                        <themed-button button-type="icon" :is-disabled="true" />
                    </div>
                    <div style="width:20px; height: 20px; margin:5px 10px;">
                        <icon iconName="circle-check" />
                    </div>
                    <div style="width:32px; height: 32px; margin:5px 10px;">
                        <icon iconName="spinning-animation" />
                    </div>
                </div>
                <div class="Playground">
                    <div class="HorizontalGroup">
                        <Selector :default-value="selectedTemplate" :options="templateOptions" type="icon-text"
                        tooltip-text="Resume Style" :icon-width="3" :icon-height="3"
                        component-id="template_selector"
                        @option-selected="handleOptionSelected" />
                        <div class="TextDescription">{{ selectedTemplate }}</div>
                    </div>
                    <div class="HorizontalGroup">
                        <Selector :default-value="selectedColor" :options="colorOptions" type="color"
                        tooltip-text="Color"
                        component-id="color_selector"
                        @option-selected="handleOptionSelected" />
                        <div class="TextDescription">{{ selectedColor }}</div>
                    </div>
                    <div class="HorizontalGroup" >
                        <Selector :default-value="selectedFont" :options="fontOptions" type="font"
                        tooltip-text="Font"
                        component-id="font_selector"
                        @option-selected="handleOptionSelected" 
                        style="width: 120px;"/>
                    </div>
                </div>
                 <!-- End Icon Buttons -->
                <!-- Draggable Sections -->
                <div class="Playground" style="width: 480px; position: relative; display: flex; flex-direction: column; margin-top: 20px; gap:20px;">
                    <draggable v-model="myList" item-key="id">
                      <template #item="{ element }">
                        <div class="RegularCard">{{ element.name }}</div>
                      </template>
                    </draggable>
                </div>
            </div>
            <div class="PreviewPanel">
                <div class="ChatContainer">
                    <div class="ChatWrapper">
                        <beautiful-chat
                        :participants="participants"

                        :onMessageWasSent="onMessageWasSent"
                        :messageList="messageList"
                        :newMessagesCount="newMessagesCount"
                        :isOpen="isChatOpen"
                        :icons="icons"
                        :showEmoji="true"
                        :showFile="true"
                        :showEdition="true"
                        :showDeletion="true"
                        :deletionConfirmation="true"
                        :showTypingIndicator="showTypingIndicator"
                        :showLauncher="false"
                        :showCloseButton="false"
                        :colors="colors"
                        :alwaysScrollToBottom="alwaysScrollToBottom"
                        :disableUserListToggle="false"
                        :messageStyling="messageStyling"
                        @onType="handleOnType"
                        @edit="editMessage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import StepperButton from './ui_controls/StepperButton.vue'; 
import ProgressBar from './ui_controls/ProgressBar.vue'; 
import TabButton from './ui_controls/TabButton.vue'; 
import Icon from './ui_controls/Icon.vue'; 
import ThemedButton from './ui_controls/ThemedButton.vue'; 
import MatchBar from './ui_controls/MatchBar.vue'; 
import Selector from './ui_controls/Selector.vue'; 
import draggable from 'vuedraggable/dist/vuedraggable.common.js';

export default {
  components: {
    StepperButton,
    ProgressBar,
    TabButton,
    Icon,
    ThemedButton,
    MatchBar,
    Selector,
    draggable,
  },
  data() {
    return {
        showProgressAnimation: false,
        showProgressError: false,
        selectedTemplate: 'London',
        templateOptions: [  
            { label: 'San Francisco', value: 'SanFrancisco', icon: 'san-francisco', description: 'A clean, modern layout with an attitude' },
            { label: 'New York', value: 'NewYork', icon: 'new-york', description: 'A modern layout with a contrast panel' },
            { label: 'London', value: 'London', icon: 'london', description: 'A classic layout with a creative twist' },
        ],
        selectedColor: '#6b147d',
        colorOptions: [
            {label: 'Blue', value: '#0E6CC2'},
            {label: 'Purple', value: '#6b147d'},
            {label: 'Green', value: '#147d2e'},
            {label: 'Red', value: 'var(--danger-700)'},
            {label: 'Mustard', value: '#945e0f'},
        ],
        selectedFont: 'Roboto',
        fontOptions: [
            {label: 'Arial', value: 'Arial'},
            {label: 'Georgia', value: 'Georgia'},
            {label: 'Helvetica', value: 'Helvetica'},
            {label: 'Monsterrat', value: 'Monsterrat'},
            {label: 'Open Sans', value: 'Open Sans'},
            {label: 'Roboto', value: 'Roboto'},
            {label: 'Times New Roman', value: 'Times New Roman'},
            {label: 'Trebuchet MS', value: 'Trebuchet MS'},
        ],
        myList: [
        { id: 1, name: 'Item 1' },
        { id: 2, name: 'Item 2' },
        { id: 3, name: 'Item 3' }
      ],
      //Related to beautiful chat
      participants: [
        {
          id: 'user1',
          name: 'Matteo',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        },
        {
          id: 'user2',
          name: 'Support',
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
        }
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      messageList: [
          { type: 'text', author: `me`, data: { text: `Say yes!` } },
          { type: 'text', author: `user1`, data: { text: `No.` } }
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    };
   },
   methods: {
    showProgressBar (error) {
      this.showProgressAnimation = !this.showProgressAnimation;
      console.log('ShowProgressAnimation: '+this.showProgressAnimation);
      this.showProgressError = error;
      if (this.showProgressAnimation && !this.showProgressError) {
        this.progressBarText = this.PROGRESS_TEXT_DEFAULT;
      } else if (!this.showProgressAnimation && this.showProgressError) {
        this.progressBarText = this.PROGRESS_TEXT_ERROR;
      } else if (!this.showProgressAnimation && !this.showProgressError) {
        this.progressBarText = this.PROGRESS_TEXT_SUCCESS
      }
    },
    // Print the option selected in the dropdown list
    handleOptionSelected(component_id, option) {
        console.log('Selector: '+component_id + ', Selected option:', option.value);
        switch (component_id) {
        case 'template_selector':
          this.selectedTemplate = option.value;
          return;
        case 'color_selector':
          this.selectedColor = option.value;
          return;
        case 'font_selector':
          this.selectedFont = option.value;
          return;
        }
    },
    //Methods related to Beauty Chat
    sendMessage (text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    },
    onMessageWasSent (message) {
      // called when the user sends a message
      this.messageList = [ ...this.messageList, message ]
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
   },
}
</script>

<style scoped>
.Playground {
    display: flex;
    justify-content: flex-start;
}
.ChatContainer {
  /* Adjust the dimensions and positioning as needed */
  width: 400px;
  height: 500px;
  margin: 20px;
  position: relative;
}

.ChatWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sc-header {
  display: none;
}
.sc-header-title {
  display: none;
}

/* Hide the avatars */
.sc-message--avatar {
  display: none;
}
</style>