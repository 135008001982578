<template>
    <div class="VerticalGroup">
        <div class="MatchGroup">
          <div :class="['MatchComponent','Box1','LeftBorder']"></div>
          <div :class="['MatchComponent',box2]"></div>
          <div :class="['MatchComponent',box3]"></div>
          <div :class="['MatchComponent', 'RightBorder', box4]"></div>
        </div>
        <div class="MatchTextRow">
            <div class="TextHeading3">{{ match}} Match</div>
        </div>
     </div>
</template>

<script>
export default {
  name: 'MatchBar',
  props: {
    match: { //The match score to display
      type: String,
      required: true,
      default:'Weak',
    },
  },
  computed: {
    //Returns the number of bars to display
    numBars() {
        switch (this.match) {
        case 'Weak':
          return 1;
        case 'Medium':
          return 2;
        case 'Strong':
          return 3;
        case 'Very Strong':
          return 4;
        default:
          return 1;
      }
    },
    //Returns the CSS classes for box2
    box2(){
        if (this.numBars>=2) return 'Box2';
    },
    box3(){
        if (this.numBars>=3) return 'Box3';
    },
    box4(){
        if (this.numBars>=4) return 'Box4';
    },
  }, 
};

</script>

<style scoped>
.MatchComponent {
  width: 6.5rem;
  height: 1.25rem;
  background-color: var(--gray-300);
  border: solid var(--border-thickness-normal) var(--gray-200);
}

.MatchComponent.Box1 {
    background-color: var(--primary-200);
}
.MatchComponent.Box2 {
    background-color: var(--primary-400);
}
.MatchComponent.Box3 {
    background-color: var(--primary-600);
}

.MatchComponent.Box4 {
    background-color: var(--primary-800);
}

.MatchComponent.LeftBorder {
    border-bottom-left-radius: var(--card-border-radius);
    border-top-left-radius: var(--card-border-radius);
}
.MatchComponent.RightBorder {
    border-bottom-right-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);
}
.MatchTextRow {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0.25rem 0 1.25rem 0;
}
.MatchGroup{
    display: flex;
    align-self: center;
}

</style>