<template>
   <div class="Scaffold FullWidth">
      <div class="Background">
        <div class="ContentContainer">
            <div class="ErrorStateContainer">
                <div class="ErrorState">
                <div class="TextHeading2">{{ ERROR_STATE_LINE1 }}</div>
                <Icon iconName="sorry"/>
                <div class="TextParagraph">{{ ERROR_STATE_LINE2 }}.</div>
                    <themed-button componentId="home" button-type="secondary"
                    :label = BUTTON_LABEL_HOME :isDisabled="false" width="fit"
                    @buttonClick="navigateHome"/>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import ThemedButton from './ui_controls/ThemedButton.vue'; 
import Icon from './ui_controls/Icon.vue'; 

export default {
  data() {
    return {
      //-------CONTENT-------
      ERROR_STATE_LINE1: 'Sorry! What you seek, can\'t be found ..',
      ERROR_STATE_LINE2: 'Let\'s start over again...',
      BUTTON_LABEL_HOME: 'Home',
    };
  },
  components: {
    Icon,
    ThemedButton,
  },
  methods: {
    navigateHome (){
        this.$router.push({ name: 'Home' }); //redirect user to home screen
    },
  }
} 
</script>

<style scoped>
.FullWidth {
    width: 100%;
}
</style>