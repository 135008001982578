<!-- IMPORTANT: Naming Convention -->
<!-- Every resume template should have a baseName e.g. UXDesigner -->
<!-- The Vue component should be called baseName + 'ResumeTemplate.vue' -->
<!-- The root div for the component should have a class= baseName -->
<!-- The external style sheet for the component should be called baseName + 'Resumemain.css' and should be in the same directory -->
<!-- All styles should be scoped to the component's class using css scoping: .baseName .className. For example: .UXDesigner .Header2-->
<template>
  <div class="UXDesigner" id="Resume">
    <div class="Resume">
        <h1 class="Title" style="left: 24px; top: 28px; ">{{ convertToSentenceCase(userEditedResume.name.split(" ")[0])
        }}<br>{{ convertToSentenceCase(userEditedResume.name.split(" ")[1]) }}</h1>
        <div class="LeftColumn" style="width: 204px; height: 612px; ">
            <!-- Summary-->
            <div class="Header2" :style="{ '--themeColor': themeColor }" v-if="userEditedResume.summary">Summary</div>
            <div class="Text-Small" style="margin-right: 20px;" v-if="userEditedResume.summary"> {{
                userEditedResume.summary }}</div>
            <!-- Contact Info-->
            <div class="Header2" :style="{ '--themeColor': themeColor }" style="margin-top: 10px;">Contact</div>
            <!-- Email-->
            <div class="icon-container">
              <div class="icon" icon-name="mail"></div>
                <div class="Link1" style="height: 10px; left: 20px; top: 2px; position: absolute"> {{
                    userEditedResume.contact_information.email }}</div>
            </div>
            <!-- Phone -->
            <div v-if="userEditedResume.contact_information.phone_number"
                style=" height: 16px; margin-top: 8px; position: relative">
                <div class="icon-container">
                  <div class="icon" icon-name="phone"></div>
                </div>
                <div style="height: 10px; left: 20px; top: 2px; position: absolute" class="Link1">{{
                    userEditedResume.contact_information.phone_number }}</div>
            </div>
            <!-- City -->
            <div v-if="userEditedResume.contact_information.city"
                style=" height: 16px; margin-top: 4px; position: relative">
                <div class="icon-container">
                  <div class="icon" icon-name="home"></div>
                </div>
                <div style="height: 10px; left: 20px; top: 2px; position: absolute" class="Text-Small">{{
                    userEditedResume.contact_information.city }}</div>
            </div>
            <!-- Education -->
            <div class="Header2" :style="{ '--themeColor': themeColor }" style="margin-top:10px; ">Education & Learning</div>
            <div style="position: relative; margin-right: 20px;">
                <template v-for="(education, index) in userEditedResume.educations">
                    <div v-if="index < 6" class="Header2" style="color: unset;">{{ education.degree }}</div>
                    <div v-if="index < 6" class="Text-Small">{{ education.school }}, {{ education.school_start_date }} – {{
                        education.school_end_date }}</div>
                    <div v-if="index < 6 && education.gpa" class="Text-Small">GPA: {{ education.gpa }}</div>
                    <div v-if="index < 6" class="Text-Small" style="margin-bottom: 10px;"></div>
                </template>
            </div>
            <!-- Skills-->
            <div class="Header2 Tools" :style="{ '--themeColor': themeColor }" v-if="userEditedResume.skills.length > 0">Skills</div>
            <div class="Text-Small Tools" style="margin-right: 10px;">
                <template v-for="(skill, index) in userEditedResume.skills">
                    <li v-if="index < 6" style="margin-right: 20px;">{{ skill }}</li>
                </template>
            </div>
            <!-- Awards-->
            <div class="Header2 Tools" :style="{ '--themeColor': themeColor }" v-if="userEditedResume.awards.length > 0">Awards</div>
            <div class="Text-Small Tools">
                <template v-for="(award, index) in userEditedResume.awards">
                    <li v-if="index < 5"
                        style="margin-right: 20px; margin-left: 15px; text-indent: -15px; word-break: keep-all">
                        {{ award }}</li>
                </template>
            </div>
            <!-- Hobbies-->
            <div class="Header2 Tools" :style="{ '--themeColor': themeColor }" v-if="userEditedResume.hobbies.length > 0">Hobbies</div>
            <div class="Text-Small Tools">
                <template v-for="(hobby, index) in userEditedResume.hobbies">
                    <li v-if="index < 3"
                        style="margin-right: 20px; margin-left: 15px; text-indent: -15px; word-break: keep-all">
                        {{ hobby }}</li>
                </template>
            </div>

        </div>
        <!-- Experiences -->
        <div class="Rectangle1" style="width: 371px; height: 792px; left: 200px; top: 24px">
            <div style="width: 351px; left: 10px; position: relative;">
                <template v-for="(experience, index) in userEditedResume.experiences">
                    <div v-if="index < 6" class="Header3" :style="{ '--themeColor': themeColor }" style="margin-top: 12px">{{ experience.company_name }}</div>
                    <div v-if="index < 6" class="Text-Body" style="font-weight: 500;">{{ experience.company_description }}
                    </div>
                    <template v-if="index < 6" v-for="(position) in experience.positions">
                        <div class="Header3" :style="{ '--themeColor': themeColor }" v-if="position.job_start_date" style="padding-bottom: 2px; font-weight: 500;">
                            {{ position.job_start_date }} – {{ position.job_end_date }}</div>
                        <div class="Header1">{{ position.job_title }}</div>
                        <div class="Header3" :style="{ '--themeColor': themeColor }" v-if!="position.job_start_date" style="padding-bottom: 10px;"></div>
                        <li v-for="(responsibility) in position.job_responsibilities" class="Text-Body"
                            style="margin-left: 10px; text-indent: -12px; padding-left: 5px; word-break: keep-all">{{
                                responsibility
                            }}</li>
                    </template>
                </template>
            </div>
        </div>
    </div>
    </div>
</template>
      
<script>
import './UXDesignerResumeStyles.css'; //Import the style sheet specific to this resume template 

export default {
  name: 'UXDesignerResumeTemplate',
  props: {
    userEditedResume: {
      type: Object,
      required: true,
      default: () => ({
        name: '',
        contact_information: {
          email: '',
          phone_number: '',
          city: '',
        },
        professional_summary: '',
        experiences: [],
        educations: [],
        skills: [],
        hobbies: [],
        awards: [],
      }),
    },
    themeColor: {
      type: String,
      default: '#0E6CC2',
    },
  },
  mounted() {
    //Use $nextTick to ensure that the DOM is fully available before attempting to set the styles. 
    this.$nextTick(() => {
      this.updateStyles(this.themeColor);
    });
  },
  watch: {
    //watch for changes to themeColor
    themeColor: {
      immediate: true, // Fire the handler immediately when the component is created
      handler(newThemeColor) {
        try {
          this.$nextTick(() => {
            this.updateStyles(newThemeColor);
          });
        } catch (error) {
          console.error('Error updating theme color:', error);
        }
      },
    },
  },
  methods: {
    updateStyles(themeColor){
    // Add the values of the CSS variables to the root element
    // This is required to (1) render the correct styles in the preview
    // The rails server will be relying on the CSS variables in the root node to replace them before generating the PDF
    // Definition: Theme color
    this.$el.style.setProperty('--themeColor', `${themeColor}`);
    // Definition: Mail Icon
    const mailIconSvg = encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.75C0 1.92157 0.671573 1.25 1.5 1.25H12.5C13.3284 1.25 14 1.92157 14 2.75V3.09195L7.3834 7.5038C7.29008 7.5638 7.15462 7.60663 6.99999 7.60663C6.84537 7.60663 6.70991 7.5638 6.61659 7.5038L0 3.09196V2.75ZM0 4.59435V11.25C0 12.0784 0.671573 12.75 1.5 12.75H12.5C13.3284 12.75 14 12.0784 14 11.25V4.59434L8.0734 8.54611L8.06764 8.54995C7.75299 8.75476 7.37513 8.85663 6.99999 8.85663C6.62486 8.85663 6.24702 8.75473 5.93237 8.54992L5.92657 8.54614L0 4.59435Z" fill="${themeColor}"/>
      </svg>
    `);
    this.$el.style.setProperty('--mail-icon-svg', `url('data:image/svg+xml;charset=UTF-8,${mailIconSvg}')`);
    // Definition: Phone Icon
    const phoneIconSvg = encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="${themeColor}" >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1735 4.06786C12.7355 3.52162 12.1228 3.14271 11.4384 2.99508C8.48849 2.43014 5.42089 2.40273 2.47452 3.0065C1.79012 3.15413 1.17742 3.53291 0.73944 4.07916C0.302438 4.62418 0.0659757 5.30276 0.0695632 6.00124L0.0639648 6.51082C0.0679866 6.86683 0.211197 7.20712 0.462948 7.45887C0.7147 7.71062 1.05499 7.8539 1.411 7.85792L3.56689 7.84657C3.92205 7.84349 4.26178 7.70103 4.51292 7.44989C4.76406 7.19875 4.9065 6.86033 4.90958 6.50517C4.91065 6.41045 4.94876 6.31992 5.01574 6.25293C5.0822 6.18647 5.17185 6.14844 5.26577 6.14681L8.65426 6.1468C8.70019 6.14646 8.74573 6.15526 8.78824 6.17268C8.83074 6.19011 8.86935 6.21581 8.90183 6.24829C8.93432 6.28077 8.96002 6.31938 8.97744 6.36189C8.99613 6.40749 9.0029 6.4549 9.00333 6.50387C9.00642 6.85902 9.14887 7.19875 9.40001 7.44989C9.65115 7.70103 9.99088 7.84351 10.346 7.84659L12.5019 7.84656C12.8579 7.84254 13.1982 7.69933 13.45 7.44758C13.7017 7.19583 13.8451 6.85554 13.8491 6.49953L13.8434 5.98992C13.8469 5.29144 13.6105 4.61288 13.1735 4.06786ZM7.625 7.93857C7.625 7.59339 7.34518 7.31357 7 7.31357C6.65482 7.31357 6.375 7.59339 6.375 7.93857V10.1595H5.12844C4.87565 10.1595 4.64775 10.3118 4.55101 10.5453C4.45427 10.7789 4.50775 11.0477 4.68649 11.2265L6.55215 13.0921C6.57477 13.1153 6.59917 13.1368 6.62515 13.1563C6.65979 13.1824 6.69652 13.2043 6.73468 13.2222C6.80252 13.2541 6.87702 13.2741 6.95551 13.2796C6.99621 13.2825 7.03712 13.2814 7.07765 13.2764C7.10765 13.2727 7.137 13.2668 7.16553 13.259C7.26681 13.2313 7.36248 13.1777 7.44204 13.0981L9.31371 11.2265C9.49246 11.0477 9.54593 10.7789 9.44919 10.5453C9.35246 10.3118 9.12456 10.1595 8.87177 10.1595H7.625V7.93857Z" fill="${themeColor}"/>
      </svg>
    `);
    this.$el.style.setProperty('--phone-icon-svg', `url('data:image/svg+xml;charset=UTF-8,${phoneIconSvg}')`);
    // Definition: Home Icon
    const homeIconSvg = encodeURIComponent(`
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="${themeColor}">
          <g clip-path="url(#clip0_1222_2267)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70711 1.29289C7.31658 0.90237 6.68342 0.90237 6.29289 1.29289L0.292893 7.29289C-0.097631 7.68342 -0.097631 8.31658 0.292893 8.70711C0.683417 9.09763 1.31658 9.09763 1.70711 8.70711L2 8.41421V13C2 13.5523 2.44772 14 3 14H11C11.5523 14 12 13.5523 12 13V8.41421L12.2929 8.70711C12.6834 9.09763 13.3166 9.09763 13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L7.70711 1.29289Z" fill="${themeColor}"/>
          </g>
          <defs>
          <clipPath id="clip0_1222_2267">
          <rect width="14" height="14" fill="white"/>
          </clipPath>
          </defs>
      </svg>
    `);
    this.$el.style.setProperty('--home-icon-svg', `url('data:image/svg+xml;charset=UTF-8,${homeIconSvg}')`);
  },
    // Function to convert a string to sentence case
    convertToSentenceCase(str) {
      if (!str) return ""; // Handle the case when the input is empty or null
      // Convert the first character to uppercase and the rest to lowercase
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
  },
};
</script>