<template>
    <div class="match-bar">
      <div class="bar-background" :style="{height: thickness}">
        <div class="bar-fill" :style="{ width: progress + '%',  backgroundColor: color}"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      percentage: {
        type: Number,
        required: true,
        validator: value => value >= 0 && value <= 100
      },
      color: {
        type: String,
        default: 'var(--success-500)'
      },
      thickness: {
        type: String,
        default: '0.25rem'
      },
    },
    data() {
      return {
        progress: 0, //This shows the width of the progress bar as a percentage of the whole bar
      };
    },
    created () {
        console.log('Progress: '+this.percentage);
        this.progress=this.percentage;
    },
    watch: {
      percentage(newValue) {
        this.progress = newValue;
      },
    },
  }
  </script>
  
  <style scoped>
  .match-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .bar-background {
    flex: 1;
    background-color: var(--gray-200);
    border-radius: var(--button-border-radius);
    overflow: hidden;
  }
  .bar-fill {
    height: 100%;
    transition: width 1s linear;
  }
  </style>