<template>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Fira+Sans">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Arimo">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Lora">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=EB+Garamond">
    <div class="SanFrancisco" id="Resume" >
        <div class="_page_container _resume_formatting" name="SanFrancisco" ref="pageContainer" v-if="userEditedResume">
            <div class="_content _full" type="Full">
                <div class="_page_resume_template">
                    <!-- Resume Header -->
                    <div class="_header">
                        <!-- Comment Icon -->
                        <div class="CommentIconContainer PositionLeft" v-if="showCommentIndicators && userEditedResume.comment_id" :data-comment-id=userEditedResume.comment_id>
                            <icon icon-name="chat-full"></icon>
                            <div class="CommentIndex ContrastColor">{{ getCommentIndex(userEditedResume.comment_id) }}</div>
                        </div>
                        <div class="_info_panel">
                            <div class="_personal_info" itemscope itemtype="http://schema.org/Person">
                                <div class="_name_panel" itemprop="name">{{ userEditedResume.name }}</div>
                            </div>
                            <div class="_contacts_panel" v-if="hasContactInfo" itemprop="contactInformation" itemscope itemtype="http://schema.org/ContactPoint">
                                <!-- City -->
                                <template v-if="userEditedResume.contact_information.city" >
                                    <div>■</div>
                                    <div class="_font_italic" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
                                        <div itemprop="addressLocality">{{userEditedResume.contact_information.city}}</div>
                                    </div>
                                </template>
                                <!-- Phone Number -->
                                <template v-if="userEditedResume.contact_information.phone_number" >
                                    <div>■</div>                          
                                    <a :href="'tel:' + userEditedResume.contact_information.phone_number" class="_font_italic" itemprop="telephone">{{ userEditedResume.contact_information.phone_number }}</a>
                                </template>
                                <!-- Email -->
                                <template v-if="userEditedResume.contact_information.email" >
                                    <div>■</div>
                                    <a :href="'mailto:' + userEditedResume.contact_information.email" class="_font_italic" itemprop="email">{{userEditedResume.contact_information.email }}</a>
                                </template>
                                <!-- LinkedIn -->
                                <template v-if="userEditedResume.contact_information.linkedin_id" >
                                    <div>■</div>
                                    <a :href="getLinkedInURL(userEditedResume.contact_information.linkedin_id)" class="_font_italic" target="_blank" >
                                        {{ getLinkedInId(userEditedResume.contact_information.linkedin_id) }}</a>
                                </template>
                                <!-- Website -->
                                <template v-if="userEditedResume.contact_information.website" >
                                    <div>■</div>
                                    <a :href="getURLString(userEditedResume.contact_information.website)" class="_font_italic" target="_blank" >
                                        {{ userEditedResume.contact_information?.website_label || userEditedResume.contact_information.website }}</a>
                                </template>
                                <!-- GitHub -->
                                <template v-if="userEditedResume.contact_information.github" >
                                    <div>■</div>
                                    <a :href="getGitHubURL(userEditedResume.contact_information.github)" class="_font_italic" target="_blank" >
                                        {{ getGitHubId(userEditedResume.contact_information.github) }}</a>
                                </template>
                                <!-- Social -->
                                <template v-if="userEditedResume.contact_information.other_social_media" >
                                    <div>■</div>
                                    <a :href="getURLString(userEditedResume.contact_information.other_social_media)" class="_font_italic" target="_blank" >
                                        {{ userEditedResume.contact_information?.other_social_media_label || userEditedResume.contact_information.other_social_media }}</a>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- Page Header -->
                    <div class="_labelTop" v-if="showIcon">
                        <svg width="60" height="142" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h60v142l-30-20-30 20V0Z" fill="currentcolor"></path>
                        </svg>
                    </div>
                    <!-- Page Footer -->
                    <div class="_labelBottom" v-if="showIcon">
                        <svg width="60" height="65" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 65h60V20L30 0 0 20v45Z" fill="currentcolor"></path>
                        </svg>
                    </div>
                    <!-- Resume Data -->
                    <div class="_resume_columns">
                        <!--Experience & Education Column-->
                        <div class="_column _main_content" id="content_column" :style="{ order: columnOrder }">
                            <!-- Professional Summary -->
                            <div v-if="userEditedResume.professional_summary && userEditedResume.professional_summary.summary" data-section-id="professional_summary" class="summary">
                                <!-- Comment Icon -->
                                <div class="CommentIconContainer" :class="pageLayout=='Left'? 'PositionRight' : 'PositionLeft'"
                                    v-if="showCommentIndicators && userEditedResume.professional_summary.comment_id" 
                                    :data-comment-id=userEditedResume.professional_summary.comment_id>
                                    <icon icon-name="chat-full"></icon>
                                    <div class="CommentIndex ContrastColor">{{ getCommentIndex(userEditedResume.professional_summary.comment_id) }}</div>
                                </div>
                                <!-- Left or Right Layout -->
                                <div class="_center_column" v-if="pageLayout!='Center'">
                                    <div data-card-id="summary" class="_resume_card">
                                        <p class="_resume_paragraph"><span>{{ userEditedResume.professional_summary.summary  }}</span>
                                        </p>
                                    </div>
                                </div>
                                <!-- Center Layout -->
                                <div class="_resume_card" v-else>
                                    <div class=" _resume_title NoBottomMargin">{{ LABEL_SUMMARY }}</div>
                                    <p class="_resume_paragraph">{{ userEditedResume.professional_summary.summary  }} </p>
                                </div>
                            </div>
                            <!-- Employment History -->
                            <div data-section-id="employment_history">
                                <!--If left or right layout-->
                                    <template v-if="pageLayout != 'Center'">
                                        <div class=" _resume_title_less_margin">{{ LABEL_EXPERIENCE }}</div>
                                        <div class="_center_column">
                                            <template v-for="(experience, index) in userEditedResume.experiences">
                                                <div class="_resume_card" :experience-id="index" >
                                                    <div class="_company_header">
                                                        <div class="_section_subtitle">{{ experience.company_name }} </div>
                                                        <div class="_resume_date" v-if="experience.company_description">{{ experience.company_description }}</div>
                                                    </div>
                                                    <template v-for="(position) in experience.positions">
                                                        <div>
                                                            <div class="_header_with_date">
                                                                <!-- Comment Icon -->
                                                                <div class="CommentIconContainer" :class="pageLayout=='Left'? 'PositionRight' : 'PositionLeft'"
                                                                    v-if="showCommentIndicators && position.comment_id" 
                                                                    :data-comment-id=position.comment_id>
                                                                    <icon icon-name="chat-full"></icon>
                                                                    <div class="CommentIndex ContrastColor">{{ getCommentIndex(position.comment_id) }}</div>
                                                                </div>
                                                                <div class="_section_title">{{ position.job_title }}</div>
                                                                <div class="_resume_date"  v-if="getDateString(position.job_start_date,position.job_end_date)?.length > 0">{{ getDateString(position.job_start_date,position.job_end_date) }}</div>
                                                            </div>
                                                            <ul class="_custom_list">
                                                                <template v-for="(responsibility) in position.job_responsibilities" >
                                                                    <li><div> {{ responsibility }} </div></li>                                  
                                                                </template>
                                                                <template v-for="(achievement) in position.job_achievements" >
                                                                    <li><div> {{ achievement }} </div></li>                                                                                             
                                                                </template>
                                                            </ul>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <!-- If center layout -->
                                    <template v-else>
                                        <div class="_resume_title_less_margin">{{ LABEL_EXPERIENCE }}</div>
                                        <div class="_center_column">
                                            <template v-for="(experience, index) in userEditedResume.experiences">
                                                <div class="_card_row" :experience-id="index" >
                                                    <div class="_section_subtitle">{{ experience.company_name }} </div>
                                                    <div class="_resume_date" v-if="experience.company_description">{{ experience.company_description }}</div>
                                                    <template v-for="(position) in experience.positions">
                                                        <div class="_header_with_date">
                                                            <!-- Comment Icon -->
                                                            <div class="CommentIconContainer PositionLeft"
                                                                v-if="showCommentIndicators && position.comment_id" 
                                                                :data-comment-id=position.comment_id>
                                                                <icon icon-name="chat-full"></icon>
                                                                <div class="CommentIndex ContrastColor">{{ getCommentIndex(position.comment_id) }}</div>
                                                            </div> 
                                                            <div class="_section_title">{{ position.job_title }}</div>
                                                            <div class="_resume_date"  v-if="getDateString(position.job_start_date,position.job_end_date)?.length > 0">{{ getDateString(position.job_start_date,position.job_end_date) }}</div>
                                                        </div>
                                                        <ul class="_custom_list">
                                                            <template v-for="(responsibility) in position.job_responsibilities" >
                                                                <li><div> {{ responsibility }} </div></li>                                  
                                                            </template>
                                                            <template v-for="(achievement) in position.job_achievements" >
                                                                <li><div> {{ achievement }} </div></li>                                                                                             
                                                            </template>
                                                        </ul>
                                                    </template>
                                                </div>
                                            </template>                                     
                                        </div>   
                                    </template>
                                </div> 
                            <!-- Education History -->
                            <div class="_resume_section" data-section-id="education_history" v-if="userEditedResume.educational_qualifications" >
                                <div :class=" pageLayout=='Center'?'_resume_title_less_margin': '_resume_title'">{{ LABEL_EDUCATION }}</div>
                                <div class="_center_column">
                                    <template v-for="(education, index) in userEditedResume.educational_qualifications.educations">
                                        <!-- If Left or Right Layout -->
                                        <div class="_resume_card" :education-id="index" v-if="pageLayout!='Center'">
                                            <div>
                                                <div class="_section_subtitle"> {{ education.school }}</div>
                                                <div class="_header_with_date">
                                                    <div class="_section_title">{{ education.degree }}</div>
                                                    <div class="_resume_date" v-if="getDateString(education.school_start_date,education.school_end_date)?.length > 0 ">
                                                        {{ getDateString(education.school_start_date,education.school_end_date) }}
                                                    </div>
                                                </div>
                                                <div v-if="education.gpa">
                                                    <div class="_resume_paragraph"> {{ education.gpa }} </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- If Center Layout -->
                                        <div class="_card_row" :education-id="index" v-else>
                                            <div class="_section_subtitle"> {{ education.school }}</div>                           
                                            <div class="_header_with_date">
                                                <div class="_section_title">{{ education.degree }}</div>
                                                <div class="_resume_date" v-if="getDateString(education.school_start_date,education.school_end_date)?.length > 0 ">
                                                    {{ getDateString(education.school_start_date,education.school_end_date) }}
                                                </div>
                                            </div>
                                            <div>
                                                <div v-if="education.gpa">
                                                    <div class="_resume_paragraph">{{ education.gpa }} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <!-- Custom Sections -->
                            <!-- If Center Layout: List All Sections -->
                            <template v-if="pageLayout=='Center'">
                                <template v-for="section in userEditedResume.section_order">
                                    <div class="_resume_section" :data-section-id="section" 
                                    v-if="userEditedResume.sections[section]?.visible && userEditedResume.sections[section]?.items_array?.length > 0">
                                        <div class="_resume_title_less_margin">{{userEditedResume.sections[section]?.name}}
                                            <!-- Comment Icon -->
                                            <div class="CommentIconContainer PositionLeft"
                                            v-if="showCommentIndicators && userEditedResume.sections[section].comment_id" 
                                                :data-comment-id=userEditedResume.sections[section].comment_id>
                                                <icon icon-name="chat-full"></icon>
                                                <div class="CommentIndex ContrastColor">{{ getCommentIndex(userEditedResume.sections[section].comment_id) }}</div>
                                            </div>
                                        </div>
                                        <!-- Date Section -->
                                        <div class="_card_row" v-if="hasDate(section)">
                                            <template v-for="(item) in userEditedResume.sections[section]?.items_array">
                                                <div class="_header_with_date" v-if="item.item_name !== ''">
                                                    <div class="_resume_paragraph">{{ item.item_name }} </div>
                                                    <div class="_resume_date" v-if="item.date!==''">{{ item.date }}</div>
                                                </div>
                                            </template>
                                        </div>
                                        <!-- Non Date Section -->
                                        <div class="_grid_2x" v-else> 
                                            <template v-for="(item, index) in userEditedResume.sections[section]?.items_array">
                                                <div class="_sidebar_card" :item-id="index" v-if="!isDateSection(section) && item !==''">
                                                    {{ "■ " + item }}
                                                </div>
                                                <div class="_sidebar_card" :item-id="index" v-else-if="isDateSection(section) && item.name !==''">
                                                    {{ "■ " + item.item_name }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <!-- If left or right layout: List only Date Sections -->
                            <template v-else>
                                <template v-for="section in userEditedResume.section_order">
                                    <div class="_resume_section" :data-section-id="section" 
                                    v-if="userEditedResume.sections[section]?.visible && hasDate(section)">
                                        <!-- Comment Icon -->
                                        <div class="CommentIconContainer" :class="pageLayout=='Left'? 'PositionRight' : 'PositionLeft'"
                                            v-if="showCommentIndicators && userEditedResume.sections[section].comment_id" 
                                            :data-comment-id=userEditedResume.sections[section].comment_id>
                                            <icon icon-name="chat-full"></icon>
                                            <div class="CommentIndex ContrastColor">{{ getCommentIndex(userEditedResume.sections[section].comment_id) }}</div>
                                        </div> 
                                        <div class="_resume_title">{{userEditedResume.sections[section]?.name}}</div>
                                        <div class="_center_column">
                                            <template v-for="(item, index) in userEditedResume.sections[section]?.items_array">
                                                <div class="_resume_card" v-if="item.item_name !== ''" :item-id="index">
                                                    <div class="_header_with_date">
                                                        <div class="_resume_paragraph">{{ item.item_name }}</div>
                                                        <div class="_resume_date" v-if="item.date!==''">{{ item.date }}</div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </template>                          
                        </div>
                        <!--Side Bar : Custom Sections Without Dates -->
                        <!-- Left or Right Layout -->
                        <div class="_column _sidebar_column" id="sidebar_column" style="order:0" v-if="pageLayout!='Center'">
                            <template v-for="section in userEditedResume.section_order">
                                <div class="_resume_section" :data-section-id="section" 
                                    v-if="userEditedResume.sections[section]?.visible && userEditedResume.sections[section]?.items_array?.length >0 && !hasDate(section)">
                                    <!-- Comment Icon -->
                                    <div class="CommentIconContainer" :class="pageLayout=='Left'? 'PositionLeft' : 'PositionRight'"
                                        v-if="showCommentIndicators && userEditedResume.sections[section].comment_id" 
                                        :data-comment-id=userEditedResume.sections[section].comment_id>
                                        <icon icon-name="chat-full"></icon>
                                        <div class="CommentIndex ContrastColor">{{ getCommentIndex(userEditedResume.sections[section].comment_id) }}</div>
                                    </div>
                                    <div class="_resume_title">{{userEditedResume.sections[section]?.name}}</div>
                                    <div class="_resume_grid">
                                        <template v-for="(item, index) in userEditedResume.sections[section]?.items_array">
                                        <div class="_resume_card _sidebar_card"  v-if="item !== ''" :item-id="index">
                                            {{ isDateSection(section)? item.item_name : item }}
                                        </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <!-- <div class="_keywords">This has some sample text to check spacing.</div> -->
                </div>
            </div>     
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import Icon from '../ui_controls/Icon.vue';

export default {
    name: 'SanFrancisco',
    props: {
        userEditedResume: {
            type: Object,
            required: true,
            default: () => ({
                name: '',
                contact_information: {
                    email: '',
                    phone_number: '',
                    city: '',
                },
                professional_summary: '',
                experiences: [],
                educational_qualifications: {
                    educations: [],
                },
            }),
        },
        pageLayout: {
            type: String,
            default: 'Left', 
        },
        showIcon: { //whether to display icons 
            type: Boolean,
            default: true,
        },
        dateSections: {
            type: Object,
            required: true,
        },
        comments: {
            type: Array,
            required: true,
        },
        showCommentIndicators: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        Icon
    },
    data() {
        return {
            LABEL_SUMMARY: 'Summary',
            LABEL_EDUCATION: 'Education',
            LABEL_EXPERIENCE: 'Experience',
        };
    },
    computed: {
        columnOrder() {
            switch (this.pageLayout) {
                case 'Left': return 1;
                case 'Right': return 0;
                default: return 0;
            }
        },
        // Whether the given section should show dates
        hasDate() {
            return (section) => {
                if (this.dateSections.includes(section)) { //If the section can have date info
                    const items = this.userEditedResume.sections[section]?.items_array;
                    if (items && items.length > 0) {
                        return items.some((item) => item.date !== '');
                    }
                } else {
                    return false;
                }
            };
        },
        // Whether the resume has at least one contact info
        hasContactInfo(){
            const contactInfo = this.userEditedResume.contact_information;
            return contactInfo && Object.keys(contactInfo).length > 0;
        },
    },
    methods: {
        // Whether the given section should show dates
        isDateSection(sectionKey) {
            return (this.dateSections.includes(sectionKey));
        },
        getDateString(start_date, end_date) {
            // Handle null/undefined cases
            start_date = start_date || '';
            end_date = end_date || '';
            
            if (start_date.trim().length > 0 && end_date.trim().length > 0) {
                return start_date.trim() + ' - ' + end_date.trim();
            } else if (start_date.trim().length > 0) {
                return start_date.trim(); // Added parentheses
            } else if (end_date.trim().length > 0) {
                return end_date.trim(); // Added parentheses
            } else {
                return '';
            }
        },
        // Prepend http:// to a string if it doesn't start with http
        getURLString(url_string) {
            if (url_string && !url_string.startsWith('http')) {
                console.log('URL string = '+url_string);
                return ('http://'+url_string);
            } else {
                return url_string;
            }
        },
        getLinkedInURL(linkedin_id) {
            const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\//i;
            if (linkedin_id) {
                if (!linkedInRegex.test(linkedin_id)) {
                    return 'https://www.linkedin.com/in/' + linkedin_id;
                } else if (!linkedin_id.startsWith('http://') && !linkedin_id.startsWith('https://')) {
                    return 'https://' + linkedin_id;
                }
            }
            return linkedin_id;
        },
        getLinkedInId(linkedin_url) {
            const linkedInRegex = /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([^\/]+)\/?/i;
            if (linkedin_url) {
                let match = linkedin_url.match(linkedInRegex);
                if (match) {
                    const id = match[1];
                    return `linkedin.com/in/${id}`;
                }
                const linkedin_alt_url = this.getLinkedInURL(linkedin_url);
                match = linkedin_alt_url.match(linkedInRegex);
                if (match) {
                    const id = match[1];
                    return `linkedin.com/in/${id}`;
                }
            }
            return linkedin_url;
        },
        getGitHubURL(github_id) {
            const gitHubRegex = /^(https?:\/\/)?(www\.)?github\.com\//i;
            if (github_id) {
                if (!gitHubRegex.test(github_id)) {
                    return 'https://www.github.com/' + github_id;
                } else if (!github_id.startsWith('http://') && !github_id.startsWith('https://')) {
                    return 'https://' + github_id;
                }
            }
            return github_id;
        },
        getGitHubId(github_url) {
            const gitHubRegex = /^(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/]+)\/?/i;
            if (github_url) {
                let match = github_url.match(gitHubRegex);
                if (match) {
                    const id = match[1];
                    return `github.com/${id}`;
                }
                const github_alt_url = this.getGitHubURL(github_url);
                match = github_alt_url.match(gitHubRegex);
                if (match) {
                    const id = match[1];
                    return `github.com/${id}`;
                }
            }
            return github_url;
        },    
        getCommentIndex(id) {
            if (this.comments && this.comments.length > 0) {
                return this.comments.findIndex(comment => comment.id === id) + 1;
            } else {
                return 'C'; // Return a default value
            }
        },    
    },
};
</script>