<template >
    <div class="CommentCard" :class="{ focus: onFocus}" v-if="hasContent" 
        @mouseover="handleMouseOver" @mouseout="handleMouseOut"
        @click="focusComment">
        <!-- Title Row -->
        <div class="CommentHeaderRow" :class="{ lessPadding : subTitle !== null }" >
            <div class="HorizontalGroup">
                <div class="CommentHeaderText TextTruncate">{{ title }}</div>
            </div>
            <!-- Minimize Button -->
            <div class="ChildCardIconButton MarginRight">
                <themed-button :componentId=componentId
                    button-type="icon" :icon="isVisible ? 'compress' : 'enlarge'"
                    :tooltip-text="isVisible ? BUTTON_LABEL_HIDE_COMMENTS: BUTTON_LABEL_SHOW_COMMENTS"
                    @buttonClick="toggleComment" />
            </div>
        </div>
        <!-- Sub title row -->
        <div class="CommentHeaderRow noTopPadding" v-if="subTitle">
            <div class="CommentText DescriptionColor TextTruncate">{{ subTitle }}</div>
        </div>
        <template v-if="type==='change_summary'">
            <!-- Change summary row -->
            <div class="CommentSummaryRow" v-if="!contentStatus || contentStatus==='generated'">
                <div class="addForeground"> +{{numLinesAdded}} </div>
                <div class="deleteForeground"> -{{numLinesRemoved}} </div>
                <div class="DescriptionColor">{{ MESSAGE_LINES_CHANGED}}</div>
            </div>
            <div class="CommentHeaderyRow DescriptionColor noTopPadding" v-else>{{ MESSAGE_USER_EDITED }}</div>
            <!-- Change details -->
            <ul class="CommentList" v-if="isVisible">
                <li class="CommentRow" :class="change_color(comment_line)" v-for="(comment_line) in content">
                    <div class="HorizontalGroup">
                        <div class="CommentBulletContainer" :class="change_color(comment_line)">{{ change_indicator(comment_line) }}</div>
                        <div class="CommentText" :class="{'StrikeThrough': contentStatus && contentStatus !== 'generated'}"> {{ comment_line.slice(4).trim() }}</div>
                    </div>
                </li>
            </ul>
            <!-- Restore Buttons -->
            <div class="CommentButtonContainer" v-if="isVisible && contentStatus && contentStatus==='generated'">
                <themed-button componentId="restore_original" width="full"
                    button-type="secondary" :label="BUTTON_LABEL_ORIGNAL"
                    @buttonClick="restoreOriginal" />
            </div>
            <div class="CommentButtonContainer" v-if="isVisible && contentStatus && contentStatus==='original'">
                <themed-button componentId="restore_generated" width="full"
                    button-type="secondary" :label="BUTTON_LABEL_GENERATED"
                    @buttonClick="restoreGenerated" />
            </div>
            <div class="CommentButtonContainer" v-if="isVisible && contentStatus && contentStatus==='edited'">
                <div class="TwoButtonRow">
                    <themed-button componentId="restore_original" width="full"
                        button-type="secondary" :label="BUTTON_LABEL_ORIGINAL_SHORT"
                        @buttonClick="restoreOriginal" />
                </div>        
                <div class="TwoButtonRow">
                    <themed-button componentId="restore_generated" width="full"
                        button-type="secondary" :label="BUTTON_LABEL_GENERATED_SHORT"
                        @buttonClick="restoreGenerated" />
                </div>
            </div>
        </template>
        <!-- ATS Score Details -->
        <template v-if="type ==='ats_score'">
            <!-- score summaries -->
            <ul class="CommentList" v-if="isVisible">
                <li class="CommentRow NoBottomPadding">
                    <div class="HorizontalGroup">
                        <div class="CommentBulletContainer">
                            <Icon iconName="circle-checklist"/>
                        </div>
                        <div class="CommentText">{{ MESSAGE_ORIGINAL_SCORE + content.original_score + ' (' + scoreToText(content.original_score) }})</div>
                    </div>
                </li>
                <li class="CommentRow noTopPadding">
                    <div class="HorizontalGroup">
                        <div class="CommentBulletContainer">
                            <Icon iconName="circle-checklist"/>
                        </div>
                        <div class="CommentText">{{ MESSAGE_NEW_SCORE + content.new_score + ' (' + scoreToText(content.new_score) }})</div>
                    </div>
                </li>
            </ul>
            <!-- chart -->
            <div class="ChartSection" v-if="isVisible">
                <div class="CommentChartContainer">
                    <doughnut-chart :percentage="content.new_score" 
                        :color="scoreToColor(content.new_score)"></doughnut-chart>
                </div>
            </div>
            <!-- update score button -->
            <div class="CommentButtonContainer" v-if="isVisible">
                <themed-button componentId="recalculate_score" width="full"
                    button-type="secondary" :label="BUTTON_LABEL_UPDATE_SCORE"
                    @buttonClick="recalculateScore" />
            </div>
            <!-- Keywords section -->
            <ul class="CommentList" v-if="isVisible">
                <!-- Keywords added -->
                <li class="CommentRow"  v-if="this.content.keywords_added?.length>0">
                    <div class="HorizontalGroup">
                        <div class="CommentBulletContainer">
                            <Icon iconName="circle-checklist"/>
                        </div>
                        <div class="CommentText"> {{MESSAGE_KEYWORDS_ADDED}}
                            <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in content.keywords_added"> {{ keyword }}</div>
                        </div>
                    </div>
                </li>
                <!-- Keywords increased -->
                <li class="CommentRow" v-if="isVisible && this.content.keywords_increased?.length>0">
                    <div class="HorizontalGroup">
                        <div class="CommentBulletContainer">
                            <Icon iconName="circle-checklist"/>
                        </div>
                        <div class="CommentText"> {{ MESSAGE_KEYWORDS_INCREASED }}
                            <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in limitedKeywords(content.keywords_increased)"> {{ keyword }}</div>
                        </div>
                    </div>
                </li>
                <!-- Keywords missing-->
                <li class="CommentRow"  v-if="isVisible && this.content.keywords_missing?.length>0">
                    <div class="HorizontalGroup">
                        <div class="CommentBulletContainer">
                            <Icon iconName="circle-checklist"/>
                        </div>
                        <div class="CommentText"> {{ MESSAGE_KEYWORDS_MISSING }}
                            <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in limitedKeywords(content.keywords_missing)"> {{ keyword }}</div>
                        </div>
                    </div>
                </li>
            </ul>
        </template>
    </div>
  </template>
  
  <script>
  import ThemedButton from './ThemedButton.vue';  //Import the themed button component
  import DoughnutChart from './DoughnutChart.vue';  //Import the doughnut chart component
  import Icon from './Icon.vue';  //Import icons library

  export default {
    name: 'comment-card',
    emits: ['buttonClick'],
    props: {
      componentId: { //the identifer for the card
        type: String,
        required: true,
      },
      title: { //The title to display for the card
        type: String,
        required: true,
      },
      subTitle: { //The sub-title to display for the card
        type: String,
        default: null,
      },
      type: { //Options = 'change_summary', 'ats_score'
        type: String,
        default: 'change_summary'
      },
      content: { //Contents to display in the card
        type: Object,
        required: true,
      },
      onFocus: { //Whether the card is being focused
        type: Boolean,
        default: false,
      },
      isVisible: { //Whether the card can be displayed
        type: Boolean,
        default: true,
      },
      contentStatus: { //Options: 'original', 'generated', 'edited'
      // This is required only for comment cards of type = 'change_summary' for the professional summary and position sections
        type: String,
      },
    },
    components: {
      ThemedButton,
      DoughnutChart,
      Icon,
    },
    data() {
        return {
            isHovered: false,
            BUTTON_LABEL_HIDE_COMMENTS: 'Hide Details',
            BUTTON_LABEL_SHOW_COMMENTS: 'Show Details',
            BUTTON_LABEL_UPDATE_SCORE:'Re-calculate',
            BUTTON_LABEL_ORIGNAL:'Restore Original Content',
            BUTTON_LABEL_GENERATED:'Restore Generated Content',
            BUTTON_LABEL_ORIGINAL_SHORT:'Original',
            BUTTON_LABEL_GENERATED_SHORT:'Generated',
            MESSAGE_NEW_SCORE:'Your new score: ',
            MESSAGE_ORIGINAL_SCORE:'Your original score: ',
            MESSAGE_USER_EDITED: 'You edited this section',
            MESSAGE_LINES_CHANGED: 'Lines changed',
            MESSAGE_KEYWORDS_ADDED: 'New keywords added:',
            MESSAGE_KEYWORDS_INCREASED: 'Existing keywords increased:',
            MESSAGE_KEYWORDS_MISSING: 'Keywords still missing: ',
        };
    },
    computed: {
        numLinesAdded() {
            return this.content.filter(line => line.startsWith('[+]')).length;
        },
        numLinesRemoved() {
            return this.content.filter(line => line.startsWith('[-]')).length;
        },
        //Returns true if it has some content to display
        hasContent() {
            return (this.type==='change_summary' && this.content.length>0 || this.type==='ats_score' && this.content.hasOwnProperty('new_score'))
        },
    },
    methods: {
        // The class that determines the comment's background color
        change_color(input) {
            if(this.isHovered || this.onFocus) {
                if (input.startsWith('[+]')) {
                return 'addBackground addForeground';
                } else if (input.startsWith('[-]')) {
                    return 'deleteBackground deleteForeground';
                }
            }
        },
        // The indicator '+' or '-' to show at the beginning of a comment
        change_indicator(input) {
            if (input.startsWith('[+]')) {
                return '+';
            } else if (input.startsWith('[-]')) {
                return '-';
            } else {
                return ' ';
            }
        },
        toggleComment() {
            this.$emit('buttonClick', 'toggle_comment_' +this.componentId);
        },
        recalculateScore() {
            this.$emit('buttonClick', 'recalculate_score');
        },
        focusComment( ) {
            this.$emit('buttonClick', 'focus_comment_' +this.componentId);
        },
        restoreOriginal( ) {
            this.$emit('buttonClick', 'restore_original_' +this.componentId);
        },
        restoreGenerated( ) {
            this.$emit('buttonClick', 'restore_generated_' +this.componentId);
        },
        handleMouseOver() {
            this.isHovered=true;
        },
        handleMouseOut() {
            this.isHovered=false;
        },
        //The icon color to use give a particular score
        scoreToColor (value) {
            if (value<35) {
                return 'var(--danger-700)';
            } else if (value<65) {
                return 'var(--warning-600)';
            } else if (value<90) {
                return 'var(--success-500)';
            }else {
                return 'var(--success-700)';
            }
        },
        //The text to show given a particular score 
        scoreToText (value) {
            if (value<35) {
                return 'Low';
            } else if (value<65) {
                return 'Medium';
            } else if (value<90) {
                return 'High';
            }else {
                return 'Superb';
            }
        },
        //Limit the number of keywords shown to 5
        limitedKeywords(keywords) {
            let modified_array;
            modified_array= keywords.slice(0, 5);
            if (keywords.length > 5) {
                const more_items = `.. and ${keywords.length-5} more`;
                modified_array.splice(5,0,more_items);
            }
            return modified_array;
        },
    },
  };
  </script>
  
  <style scoped>
    .CommentCard {
        margin: 10px 15px;
        padding: 1rem 0.625rem;
        width: calc(100% - 30px);
        color: var(--gray-900);
        background-color: var(--gray-100);
        border-radius: var(--card-border-radius);
        border: solid var(--border-thickness-normal) var(--color-card-border);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        transition: transform var(--transition-time) ease-in-out;
    }
    .CommentCard.hide {
        transform: translateY(-250rem);
    }

    .CommentCard.focus{
        background-color: var(--color-background-canvas);
        border-color: var(--color-input-normal);
        /* transform: translateX(-10px); */
        box-shadow: var(--shadow-elevation-three);
    }

    .CommentCard:hover{
        background-color: var(--color-background-canvas);
        /* box-shadow: var(--shadow-elevation-three); */
    }

    .CommentList {
        list-style-type: none; /* Remove default bullet point */
        margin: 0;
        width: 100%;
    }
    .CommentRow{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.4rem 0;
        width: 100%;
    }
    .CommentHeaderRow{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.625rem 0 0.625rem var(--border-thickness-selected);
        width: 100%;
    }
    .CommentHeaderText {
        position: relative;
        font-size: var(--size-normal); 
        font-weight: var(--weight-bold); ; 
        line-height: var(--size-normal);
        color: var(--color-text-paragraph);
        text-align: left;
        align-self: center;
    }

    .CommentHeaderText.focus{
        color: var(--color-input-selected);
    }

    .CommentText {
        position: relative;
        font-size: var(--size-small); 
        font-weight: var(--weight-normal); ; 
        line-height: var(--size-small);
        color: var(--color-text-paragraph);
        text-align: left;
        width: calc(100% - 2.5rem);
    }

    .CommentBulletContainer {
        height: 1.25rem;
        width: 1.25rem;
        margin: var(--border-thickness-selected);
        color: var(--color-text-description);
        align-self: flex-start;
    }

    .CommentIconButton {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 0.625rem 0 0;
        align-self: center;
    }
  .CommentSummaryRow {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    font-size: var(--size-small); 
  }
  .CommentChartContainer {
    width: 10rem;
    height: 10rem;
  }
  .ChartSection{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .CommentButtonContainer {
    width: calc( 100% - 3.75rem);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.75rem 0;
    align-self: center;
    gap: 0.625rem;
 }
  .TwoButtonRow {
    width: 50%;
    display: flex;
    justify-content: center;
    gap: 0.625rem;
 }
  .addBackground {
    background-color: var( --success-100);
  }
  .addForeground {
    color: var(--success-700);
  }
  .deleteBackground {
    background-color: var(--danger-50);
  }
  .deleteForeground {
    color: var(--danger-900);
  }
  .hide {
    display: none;
  }
  .lessPadding {
    padding-bottom: 0.25rem;
  }
  .noTopPadding {
    padding-top: 0;
  }
  </style>