<template>
  <div class="Scaffold CoverLetterWidth" v-if="userDetails && userDetails.terms_content">
    <div class="Background">
      <div class="ContentContainer">
        <div class="EditorPanel">
          <div class="PanelContainer BorderRight">
            <!--Page Instructions-->
            <div class="RegularCard MarginBottom">
              <div id="Header">
                <div class="CardHeaderRow">
                  <div class="TextParagraph">Hi {{ this.userDetails.user_first_name }},</div>
                </div>
                <div class="RowAlignedLeft">
                  <div class="TextParagraph">{{ userDetails.existing_user? GREETING_EXISTING_USER: GREETING_NEW_USER}}</div>
                </div>
              </div>
            </div>
            <!--Summarized Terms-->
            <div class="RegularCard" v-if="summaryTerms">
                <!-- Card Header -->
                <div class="CardHeaderRow">
                    <div class="CardHeaderIcon">
                      <icon icon-name="summary" :icon-stroke=1.5></icon>
                    </div>
                    <div class="CardHeaderHorizontalGroup">
                      <div class="TextHeading1">{{ userDetails.existing_user? LABEL_SUMMARY_EXISTING_USER : LABEL_SUMMARY_NEW_USER }}</div>
                    </div>
                    <div class="CardIconButton"></div>
                    <div class="CardIconButton">
                        <themed-button componentId="toggle_summary_card" button-type="icon"
                        icon="circle-up-arrow" :rotate="true" :toggle-state="is_summary_expanded"
                        :tooltip-text="is_summary_expanded? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                        @buttonClick="handleButtonClick"/>
                    </div>
                </div>
                <div class="RegularCardSection">
                    <div class="CardButtonRow">
                        <div class="TextDescription">{{userDetails.existing_user? SUMMARY_EXISTING_USER: SUMMARY_NEW_USER}}</div>
                    </div>
                </div>
                <!-- Card Body -->
                <div class="RegularCardSection" :class="is_summary_expanded?'':'collapsed'">
                    <!--List Summary-->
                    <ul class="TermsList">
                        <li class="TermsRow" v-for="(term, index) in summaryTerms" :key="index">
                            <div class="TermsText">
                                <b>{{ term.header + ': '}}</b>{{ term.details }}
                            </div>
                        </li>
                        <li class="TermsRow" v-if="!userDetails.existing_user">
                            <div class="TermsText">
                                <b>{{ SUMMARY_NOTICE_TERM_HEADER }}</b>{{ SUMMARY_NOTICE_TERM_DETAIL + userDetails.user_email }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
        <div class="PreviewPanel">
            <div class="TermsPanelContainer">
                <div class="PreviewScaffold">
                    <div class="CoverLetterPreview">
                        <div class="terms-content" v-html="detailedTerms"></div>
                    </div>
                </div>
            </div>
            <!--Acceptance buttons-->
            <div class="StickyContainer BorderTop BorderRight">
                <div class="TermsButtonRow">
                    <themed-button componentId="reject" button-type="secondary"
                    :label = BUTTON_LABEL_REJECT  width="fit"
                    @buttonClick="handleButtonClick"/>
                    <themed-button componentId="accept" button-type="primary" 
                    :label = BUTTON_LABEL_ACCEPT width="fit"
                    @buttonClick="handleButtonClick"/>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Icon from './ui_controls/Icon.vue'; 
import ThemedButton from './ui_controls/ThemedButton.vue'; 

export default {
    components: {
        ThemedButton,
        Icon,
     },
    data() {
        return {
            acceptedTerms: false,
            is_summary_expanded: true, //the summary card toggle state
            // --- CONTENT ---
            BUTTON_LABEL_ACCEPT: 'Accept',
            BUTTON_LABEL_REJECT: 'Cancel',
            LABEL_SUMMARY_NEW_USER: 'Summary of Terms',
            LABEL_SUMMARY_EXISTING_USER: 'Summary of Changes',
            TOOLTIP_EXPAND: 'Expand',
            TOOLTIP_COLLAPSE: 'Minimize',
            GREETING_EXISTING_USER: 'We have made some updates to our terms. Please review and accept them in order to continue.',
            GREETING_NEW_USER: 'Thanks for your interest in upplai. Please review and accept our terms in order to continue.',
            SUMMARY_NEW_USER: 'We have summarized of our terms of service below for your quick review. We still recommend that you read through the detailed terms of service on the right before you proceed.',
            SUMMARY_EXISTING_USER: 'We have summarized the changes to our terms of service below for your quick review. We still recommend that you read through the detailed terms of service on the right before you proceed.',
            SUMMARY_NOTICE_TERM_HEADER: 'Notices: ',
            SUMMARY_NOTICE_TERM_DETAIL: 'Notices to you will be set to the email address ',
        };
    },
    computed: {
        ...mapGetters(['getUserDetails']),
        ...mapGetters(['previousRoute']), //get previous page name
        userDetails() {
            return this.getUserDetails;
        },
        detailedTerms() {
            return this.userDetails?.terms_content;
        },
        summaryTerms() {
            if (this.userDetails?.existing_user && this.userDetails?.existing_user_terms_summary) {
                return this.userDetails?.existing_user_terms_summary;
            } else {
                return this.userDetails?.new_user_terms_summary;
            }
        },
    },
  methods: {
    handleButtonClick (id){
      console.log('Clicked button ID: '+id);
      if (id==='reject') {
        //Redirect user to login screen
        console.log('User did not accept terms..redirecting to login screen..');
        this.redirectToLoginScreen(this.$route.name);
      } else if (id==='accept') {
        //Redirect user to home screen
        this.updateUserAndGoHome();
      } else if (id==='toggle_summary_card') {
        this.is_summary_expanded = !this.is_summary_expanded;
      }
    },
    async updateUserAndGoHome() {
        const now = new Date();
        try {

            const response = await axios.patch(`/api/v1/users/${this.userDetails.user_id}`, {
                terms_id: this.userDetails.terms_id,
                terms_agreed_at: now.toISOString(),
            });
            if (response.status === 200) {
                console.log('User login and terms updated:', response.data);
                //Update pending terms in Vue store to false since the user has accepted the terms
                const user_details = {pending_terms:false};
                this.$store.commit('updateUserDetails', { userDetails: user_details });
                // Navigate to the home screen
                this.$router.push({ name: 'Home' });
            } else {
                //Redirect user to login screen
                console.log('User terms acceptance could not be updated.. '+JSON.stringify(response));
                this.redirectToLoginScreen(this.$route.name);
            }

        } catch (error) {
            // In case there's an error making any of the API calls
            console.error('Error updating user terms acceptance:', error);
            this.redirectToLoginScreen('Login');
        }
    },
    //Redirect user to login screen
    redirectToLoginScreen(from_page){
      this.$store.commit('setPreviousRoute', from_page); //save the previous route name in Vue store
      if (this.userDetails.existing_user) {
          this.$router.push({ name: 'Login', query: {existing_user: true} }); //login experience for existing users
      } else {
          this.$router.push({ name: 'Login'}); //login experience for new users
      }
    },
  },
};
</script>
<style scoped>
.TermsButtonRow {
  display: flex;
  justify-content: flex-end;
  margin: 0.625rem 0 0.25rem 0;
  height: 2rem;
  flex-direction: row;
  width: calc(100% - 2.5rem);
  align-items: center;
  align-self: center;
  gap: 0.625rem;
}

.TermsList{
    list-style-type: circle; 
    padding-left: 2.25rem;
    margin-top: 0.625rem;
    width: 100%;
}
.TermsRow{
    padding: 0.25rem 1.25rem 0.25rem 0;
    width: 100%;
}
.TermsText { /* A version of TextParagraph with more line heigh */
    font-size: var(--size-normal); 
    font-weight: var(--weight-normal);
    line-height: var(--size-x-large);
    color: var(--color-text-paragraph);
    text-align: left;
}
</style>