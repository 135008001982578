<template>
    <div>
          <div class="ProgressBackground">
            <div class="Progress"> {{ progressDisplay }}%</div>
            <div class="ProgressBar">
              <div class="ProgressForeground" :style="{ width : progress + '%' }">
                <div class="StripedPattern" v-if="showAnimation"></div>
              </div>
            </div>
          </div>
          <div class="ProgressTextRow">
            <div class="TextDescription" :class="textClasses" v-if="text">{{ text.toUpperCase() }}</div>
        </div>
     </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    resetProgress: { //changes to this property will reset the progress bar to 0
      type: Number,
      default: 0,
    },
    totalDuration: { //the total duration it takes for the progress bar to go from 0% to 100%
      type: Number,
      default: 5,
    },
    showProgressAnimation: { //setting it to false stops the animation
      type: Boolean,
      default: false,
    },
    isError: { //If error = true, progress animation will stop and show the 'text' below in red
      type: Boolean,
      default: false,
    },
    text: { //The text to display below the progress bar
      type: String,
    },
  },
  data() {
    return {
      progress: 0, //This shows the width of the progress bar as a percentage of the whole bar
      showAnimation: this.showProgressAnimation, //Set the initial state based on the value of the property
      lastAnimationTime: 0,
      animationFrameId: null,
    };
  },
  computed: {
      textClasses() {
        return {
          'normal': !this.isError, //normal state if no error
          'error': this.isError,
        };
      },
      // return the progress as an integer
      progressDisplay() {
        return Math.floor(this.progress); 
      }
    },
  watch: {
    showProgressAnimation(newValue) { //watch for changes to the showAnimation property
      if (newValue) {  //If showProgressAnimation is changed to true
        this.startProgressBar(); //start the progress animation if showAnimation property = true
      } else { //If showProgressAnimation is changed to false
        if (this.isError) {
          this.stopProgressBar(null); //stop the progress animation at the current progress%
        } else {
          this.stopProgressBar(100); //stop the progress animation and reset progress to 100%
        }
      }
    },
    resetProgress: 'resetProgressBar',
  },
  methods: {
    //reset the progress bar
    resetProgressBar() {
      this.progress=0;
    },
    //start the progress bar animation
    startProgressBar() {
      this.progress = 0; //Start progress from 0
      this.showAnimation = true;
      this.lastAnimationTime = performance.now();
      this.animate();
    },
    //Animate the progress bar
    animate() {
      if (this.showAnimation) {
        const currentTime = performance.now();
        const elapsedTime = currentTime - this.lastAnimationTime;
        this.progress += (elapsedTime / (this.totalDuration * 1000)) * 100;
        if (this.progress < 100) {
          this.animationFrameId = requestAnimationFrame(this.animate);
          this.lastAnimationTime =currentTime; //update the last animation time
        } else {
          this.stopProgressBar();
        }
      }
    },
    //Stop the progress bar animation
    stopProgressBar(value) {
      if (value) this.progress = value; //If there's a % value at the time of stopping, retain that after animation stops
      cancelAnimationFrame(this.animationFrameId);
      this.showAnimation = false;
    },
  },
};
</script>

<style scoped>
.ProgressBackground {
  width: 20rem;
  height: 1.25rem;
  background-color: var(--color-input-disabled);
  overflow: hidden;
  border-radius: var(--card-border-radius);
}

.ProgressTextRow {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.625rem;
}

.TextDescription.normal {
  color: var(--gray-600);
}
.TextDescription.error {
  color: var(--danger-700);
}
.ProgressBar {
  width: 100%;
  height: 100%;
}

.Progress{
    position: absolute;
    z-index: calc(var(--z-index-default) + 1);
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--size-x-small);
    color: var(--gray-700);
}

.ProgressForeground {
  position: relative;
  height: 100%;
  background-color: var(--primary-300);
  /* background: linear-gradient(to right, var(--primary-300), var(--secondary-300)); */
  transition: width 1s linear; /* Transition for smooth width changes */
  overflow: hidden;
}

.StripedPattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%; /* So that when it goes from -50% to +50%, it can still cover 100% of the width */
  height: 100%;
  background: repeating-linear-gradient(-45deg, transparent, transparent 10px, var(--secondary-10) 3px, var(--secondary-10) 13px); /* Adjust the size and color of the stripes */
  animation: stripeAnimation 5s linear infinite;
}

@keyframes stripeAnimation {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%); /* Adjust the distance based on the width of the striped pattern */
  }
}
</style>