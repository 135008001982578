// Import and register all your controllers from the importmap under controllers/*

// Eager load all controllers defined in the import map under controllers/**/*_controller
//import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
//eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

import { createWebHistory, createRouter } from "vue-router";
import store from '../store';
import Login from "../components/Login.vue";
import InputForm from "../components/InputForm.vue";
import Resume from "../components/Resume.vue";
import CoverLetter from "../components/CoverLetter.vue";
import Terms from "../components/Terms.vue";
import DesignSystem from "../components/DesignSystem.vue"; //This is an internal screen
import NotFound from "../components/NotFound.vue";

const routes = [
  {
    path: "/login", 
    name: "Login",
    component: Login,
    meta: { hideNavigation: true }
  },
  {
    path: "/",
    name: "Home",
    component: InputForm,
  },
  {
    path: "/resume",
    name: "Resume",
    component: Resume,
  },
  {
    path: "/cover-letter", 
    name: "Cover Letter",
    component: CoverLetter,
  },
  {
    path: "/terms", 
    name: "Terms",
    component: Terms,
  },
  {
    path: "/design", //This is an internal screen
    name: "Design",
    component: DesignSystem,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('setPreviousRoute', from.name);
  next();
});

export default router;