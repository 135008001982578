<template>
    <div id="nav" v-if="!$route.meta.hideNavigation"> 
      <router-link :to="{ name: 'Home' }">
        <!-- Upplai Logo -->
        <div class="LogoContainer">
          <icon icon-name="upplai-logo"></icon>
        </div>
      </router-link> 
      <!-- Sign out -->
      <div @click="signOut">
        <div class="IconContainerMini">
          <themed-button component-id="sign_out" button-type="icon" :is-disabled="false" icon="sign-out" :tooltip-text="TOOLTIP_SIGNOUT"/>
        </div>
      </div>
    </div>
    <!-- Toast Well region -->
    <div id="toasts_well" role="region" aria-live="polite">
        <section id="upplai-toasts" class="ToastWell" aria-label="Toast message">
          <header class="ToastWellHeader">
            <h2>{{ toasts.length }} notification{{ toasts.length !== 1 ? 's' : '' }} total</h2>
          </header>
          <p class="ToastInstructions">Dismiss the current notification to hear the previous.</p>
          <div class="ToastList">
            <!-- <toast-message :message="JOB_MESSAGE_VALID_URL" :show="true" :messageType="'Info'" @dismiss-toast="dismissToast" /> -->
            <toast-message
              v-for="toast in toasts" :key="toast.id"
              :id="toast.id"
              :type="toast.type"
              :message="toast.message"
              :visible="toast.visible"

              :count = "toast.count"
              @dismissToast="removeToast"
            />
          </div>
        </section>
    </div>
    <router-view />
</template>

<script>
import eventBus from './utils/event-bus';
import {ToastEvents} from './utils/toast-events';
import ThemedButton from './components/ui_controls/ThemedButton.vue'; 
import ToastMessage from './components/ui_controls/ToastMessage.vue'; 
import Icon from './components/ui_controls/Icon.vue'; 
import axios from 'axios';

export default {
  components: {
    ThemedButton,
    ToastMessage,
    Icon,
  },
  data() {
    return {
      toasts: [], // You can manage the toasts array here
      // --- CONTENT ---
      TOOLTIP_SIGNOUT: 'Sign Out',
    };
  },
  methods: {
    removeToast(toastId) {
      // Find and remove the toast from the array
      // console.log('Inside App.vue removeToast');
      const index = this.toasts.findIndex((toast) => toast.id === toastId);
      // console.log('removeToast: Index of toast to be removed: '+index);
      if (index !== -1) { //i.e. toast was found in the array
        setTimeout(() => {
          // Set visibility to true for the next recent toast, if any
          // console.log('removeToast: Num toast messages available: '+this.toasts.length);
          if (index>0) { //i.e. it's not the only remaining toast
            this.toasts[index-1].visible = true; 
          }

          this.toasts.splice(index, 1); //remove the toast
          // console.log('removeToast: Num toast messages after removal: '+this.toasts.length);
        },300); //Set a 300ms delay to show dismiss animation when a user manually dismisses a toast
          
      }
    },
    //Save and sign out user
    async signOut() {
      // Perform any necessary sign out logic here, such as clearing user data or tokens
      try {
        const response = await axios.post('/api/v1/logout/');
      } catch (error) {
        // In case there's an error logging out
        console.error('Error logging out:', error);
      }
      // Navigate to the existing user Login screen (i.e. with query param existing_user=true)
      this.$router.push({
        name: 'Login',
        params: { from: this.$route.name },
        query: { existing_user: true } // login experience for existing user
      });
    },
  },
  created() {
    // Listen for events from the event bus
    // If it's an add toast event (i.e. a toast is being programmatically added)
    eventBus.on(ToastEvents.ADDED, (newToast) => {
      // console.log('Inside App.vue '+ Date.now() +': Received toast add event');
      const index = this.toasts.findIndex((toast) => toast.id === newToast.toastId);
      // console.log('event:ToastRemoved: Index of toast to be added: '+index);
      if (index == -1) { //i.e. toast was not found in the array
        // Set visibility to false for existing toasts
        this.toasts.forEach((toast) => {
          // console.log('Toast message: '+toast.message);
          toast.visible = false;
        });
        newToast.count = this.toasts.length+1;
        // console.log('event:ToastAdded'+ Date.now() +': Num toast messages available: '+newToast.count);
        // Add the toast to the toasts array
        this.toasts.push(newToast);
      } 
    });
    // If it's a remove toast event (i.e. a toast is being programmatically dismissed)
    eventBus.on(ToastEvents.REMOVED, (toastId) => {
      // console.log('Inside App.vue'+ Date.now() +': Received toast remove event');
      // Find and remove the toast from the array
      const index = this.toasts.findIndex((toast) => toast.id === toastId);
      // console.log('event:ToastRemoved: Index of toast to be removed: '+index);
      if (index !== -1) { //i.e. toast was found in the array
        // NOTE: DO NOT USE A DELAY when toasts are dismissed programmaticaly. Otherwise it can lead to erroneous counts for new toasts which are fired during the delay period
        // Set visibility to true for the next recent toast, if any
        // console.log('event:ToastRemoved'+ Date.now() +': Num toast messages available: '+this.toasts.length);
        if (index> 0) { //i.e. it's not the only remaining toast
          this.toasts[index-1].visible = true;
        }
        this.toasts.splice(index, 1);
        // console.log('event:ToastRemoved: Num toast messages after removal: '+this.toasts.length);
      }
    });
  },
}
</script>

<style scoped>

.LogoContainer {
  height: 40px;
  position: absolute;
  top: 2px;
}
</style>