<template>
    <div class="Selector" :class="selectorClasses">
      <!-- Header -->
      <div class="DropdownHeader" :class="headerClasses"
       @mouseover="handleMouseOver" @mouseout="handleMouseOut" @keydown="handleKeyDown" @click="handleSelectorClick">
        <div class="HeaderIconContainer" v-if="type==='icon-text'">
            <icon :iconName="selectedOption.icon"/> 
        </div> 
        <div class="HeaderIconContainer" v-else-if="type==='color'"> <!-- Palette icon when it's a color selector -->
            <svg width="100%" height="96%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.7502 4.40002V4.42772M7.77769 9.40024H7.75M16.2858 5.86465L16.2662 5.88423M9.23431 12.9161L9.21473 12.9357M9.23431 5.88391L9.21473 5.86433M12.75 17.4C8.33172 17.4 4.75 13.8183 4.75 9.40002C4.75 4.98175 8.33172 1.40002 12.75 1.40002C17.1683 1.40002 20.75 4.98175 20.75 9.40002C20.75 10.7451 19.5072 11.64 18.1621 11.64H17.64C17.3704 11.64 17.1045 11.7028 16.8633 11.8234C16.0054 12.2523 15.6577 13.2955 16.0867 14.1533C16.2072 14.3945 16.27 14.6604 16.27 14.93V15.0876C16.27 16.0048 15.7553 16.8733 14.8708 17.1159C14.1954 17.3011 13.4842 17.4 12.75 17.4Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="3.25" y="20.5" width="19" height="3" :fill="selectedOption.value" :stroke="selectedOption.value"/>
            </svg> 
        </div>
        <div class="HeaderTextContainer" v-if="type==='font' || type ==='number' || type ==='percentage'"> 
            <div class="HeaderRowText TextTruncate" :class="selectorClasses">{{ selectedOption.label }}</div> 
        </div> 
        <div class="DropdwonArrowContainer">
            <icon :iconName="isDropdownExpanded? 'up-arrow-filled': 'down-arrow-filled'"/>
        </div>
      </div>
      <div class="tooltip" :class="tooltipClasses">{{ tooltipText }}</div> 
      <!-- Dropdown List -->
      <transition name="fade">
        <div class="OptionsDropdown" :class="dropdownClasses">
          <div class="OptionsRow" :class="option == selectedOption? 'selected':'' " v-for="(option, index) in options" :key="index" 
          @click="handleDropdownClick(option)">
            <div class="OptionsRowIconContainer" :style="{ width: iconWidth + 'rem', height: iconHeight + 'rem' }"  v-if="type=='icon-text'">
                <icon :iconName="option.icon" :iconStroke="1"/> 
                <!-- reducing the stroke width by 2 since the height of the icon is 3rem i.e 48px instead of 24px -->
             </div>
            <div class="OptionsRowIconContainer" :style="{ width: iconWidth + 'rem', height: iconHeight + 'rem', color: option.value }"  v-else-if="type=='color'">
                <icon iconName="gem"/> 
             </div>
             <div class="OptionsRowVerticalGroup">
                <div class="OptionsRowText"  v-if="option.label">
                    <div class="TextParagraph" v-if="type!='font'">{{ option.label }}</div>
                    <div class="TextParagraph TextTruncate" :style="{'font-family': option.value , 'margin-left' : '0.625rem'}" v-else>{{ option.label }}</div>
                </div>
                <div class="OptionsRowText" v-if="option.description && type!='font'">
                    <div class="OptionsRowDescription" >{{ option.description }}</div>
                </div>
             </div>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import Icon from './Icon.vue'; 

  export default {
    props: {
      // --- General Settings -------
      componentId: {
        type: String,
        required: true,
      },
      type: {    //the selector type; available options:
        // (1)'icon-text' - shows an icon + text + optional description
        // (2) 'font' - shows font names in the specified font
        // (3) 'color' - shows color circles in the dropdown with the appropriate color
        // (4) 'number' - shows a numerical dropdown up to 2 characters wide
        // (5) 'percentage' - shows a numerical dropdown up to 4 characters wide
        type: String,
        defaul: 'icon-text',
      },
      tooltipText: {    //tooltip text to show in the header row
        type: String,
      },
      isDisabled: {  //whether the selector is disabled
        type: Boolean,
        default: false,
      },
      // --- Options and Values ------
      options: { // array of options to display in  the dropdown list
        type: Array,
        required: true
      },
      defaultValue: { //The default value selected
        validator: function(value) {
            return typeof value === 'string' || typeof value === 'number';// Check if the value is either a string or a number
        }
      },
      // ---- Formatting ----
      iconHeight: { // Height of the image inside the dropdown
        type: Number,
        default: 2 // Default image size in rem
      },
      iconWidth: { // Width of the image inside the dropdown
        type: Number,
        default: 2 // Default image size in rem
      },
      maxDisplayOptions: {
        type: Number,
        default: 5 // Default max number of options to display; additional options would need to be scrolled
      },

    },
    components: {
        Icon,
    },
    data() {
      return {
        isDropdownExpanded: false,
        tooltipVisible: false,
        isHoverState: false,
        isFocusState: false,
        isActiveState: false,
        pressedReturnKey: false, //did the user press the return key?
        handlingClick: false, //when click is being handled
        optionSelected: null, //the option selected
        disabledState: this.isDisabled, 
      };
    },
    computed: {
      // Find the selected option based on the value passed from the parent or the first option if no such option is found
      selectedOption() {
        if (this.optionSelected) {
            return this.optionSelected;
        } else {
            return this.options.find(option => option.value === this.defaultValue) || this.options[0];
        }
      },
      displayedOptions() {
        return this.options.slice(0, this.maxDisplayOptions);
      },
      dropdownClasses() {
        let classList =[];
        if (this.isDisabled) return 'hidden';
        if (this.isDropdownExpanded) { 
            classList.push('visible');
        } else {
            classList.push('hidden');
        }
        if (this.options.length > this.maxDisplayOptions) classList.push('scroll');
        return classList;
      },
      tooltipClasses() {
        if (!this.isDropdownExpanded && this.tooltipVisible) {
          return 'visible';
        } else {
          return 'hidden';
        }
      },
      headerClasses() {
        let classList =[];
        if (this.isDisabled) classList.push('disabled');
        if (this.type==='font') classList.push('font');
        if (this.isHoverState && !this.isDropdownExpanded) {
            classList.push('hover');
        } else if (!this.isDisabled && (this.isFocusState || this.isActiveState || this.pressedReturnKey) ){
            classList.push('selected');
        }
        return classList;
      },
      selectorClasses() {
        if (this.type=='font') return 'text-width';
        else if (this.type=='percentage') return 'percentage-width';
        else return 'icon-width';
      },
    },
    methods: {
      toggleDropdown() {
        if (!this.isDisabled) this.isDropdownExpanded = !this.isDropdownExpanded;
      },
      // Mouseover on header row/ selector
      handleMouseOver() {
        if (!this.isDropdownExpanded) {
            this.isHoverState = true;
            this.pressedReturnKey=false;
            if (this.tooltipText) {
                this.tooltipVisible = true;
            }
        }
      },
      // Mouse out action on header row/ selector
      handleMouseOut() {
        this.isHoverState = false;
        this.isFocusState = false;
        this.tooltipVisible = false;
        this.pressedReturnKey=false;
      },
      // Keydown action on header row/ selector
      handleKeyDown(event) { //When a user enters a key on the button
        console.log('Key Down');
        if (event.key === 'Enter' || event.key === ' ') {
          this.pressedReturnKey=true;
          this.toggleDropdown();
        } else {
          this.pressedReturnKey=false;
        }
      },
      // Focus action on header row/ selector
      handleFocus() { //when a user tabs in using a keyboard
        // console.log('Focus State');
        if (!this.isDropdownExpanded) {
            this.isFocusState = true;
            if (this.tooltipText) {
            this.tooltipVisible = true;
            }
        }
      },
      handleBlur() { //when a user tabs out of the selector using a keyboard
        this.isFocusState = false;
        this.tooltipVisible=false;
      },
      // Click action on header row/ selector
      handleSelectorClick() {
        this.handlingClick = true; // click is being processed
        setTimeout(() => { // Set a timeout to reset isActiveState after 200ms
            if (!this.isDropdownExpanded) {
                this.isActiveState = true;
            } else {
                this.isActiveState = false;
            }
            this.toggleDropdown();
            this.handlingClick = false; // click processing is complete
            }, 200);
      },
      // Click action on the dropdown
      handleDropdownClick(option) {
        this.handlingClick = true; // click is being processed
        setTimeout(() => { // Set a timeout to reset isActiveState after 200ms
            this.optionSelected = option;
            this.isDropdownExpanded = false;
            this.isActiveState = false;
            this.handlingClick = false; // click processing is complete
            console.log('emitting option-selected for coomponent id = '+this.componentId);
            this.$emit('option-selected', this.componentId, option); //emit an event to the parent informing them about the option that was selected
            }, 200);
      },
      // Method to handle clicks outside the Selector component
      handleClickOutside() {
        // console.log('Selector - handle click outside event listener method');
        if (!this.handlingClick) {
            // console.log('Click was outside the selector and dropdown');
            this.isActiveState = false;
            this.isDropdownExpanded = false;
        }
      },
    },
    async created() {
        //Set the default option
        await this.$nextTick();
        this.optionSelected= this.options.find(option => option.value === this.defaultValue) || this.options[0];
        // console.log('Selector: selected value = '+this.optionSelected.value + ', default value = '+this.defaultValue);
    },
    watch: {
        // Update the selectedOption when the default value changes
        defaultValue(newValue) {
            this.optionSelected = this.options.find(option => option.value === newValue) || this.options[0];
            // console.log('Selector: Default selection changed to '+newValue + ', updated value = '+this.optionSelected.value);
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside); // Add a click event listener to the document
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside); // Remove the click event listener when the component is destroyed
    },
  };
  </script>
  
  <style scoped>
  .Selector {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 2rem;
  }

  .Selector.text-width {
    width: 7.5rem;
  }
  .Selector.percentage-width {
    width: 4.25rem;
  }
  .Selector.icon-width {
    width: 3rem;
  }
  
  .DropdownHeader {
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 0.25rem;
    padding: 0.25rem;
    min-width: 3rem;
    height: 2rem;
  }
  .DropdownHeader.hover {
    color: var(--color-input-normal);
    background-color: var(--color-background-primary);
    border-radius: var(--button-border-radius);
  }
  .DropdownHeader.selected {
    color: var(--color-input-selected);
    background-color: var(--color-outline-hover);
    border-radius: var(--button-border-radius);
  }
  .DropdownHeader.font {
    justify-content: space-around;
  }
  .DropdownHeader.disabled {
    cursor: not-allowed;
    color: var(--color-outline-disabled);
  }
 

  .HeaderIconContainer {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.125rem;
  }

  .HeaderTextContainer{
    display: flex;
    align-items: center;
    overflow: hidden;
    text-align: left;
    padding: 0 0.125rem;
}

.HeaderRowText {
    line-height: 1.5rem;
    font-size: var(--size-normal);
}
.HeaderRowText.text-width {
    width: 5.5rem;
}
.HeaderRowText.number-width {
    width: fit-content;
}

  .DropdwonArrowContainer {
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .tooltip {
    position: absolute;
    top: 2.25rem;
    width: fit-content;
    align-self: flex-start;
    background-color: var(--color-input-secondary);
    color: var(--color-background-canvas);
    padding: 0 0.25rem;
    border-radius: var(--button-border-radius);
    font-size: var(--size-x-small);
    white-space: nowrap;
    z-index: var(--z-index-tooltip);
}

.tooltip.visible {
  display: block;
}

.tooltip.hidden {
  display: none;
}
  .OptionsDropdown {
    position: absolute;
    top: 2.25rem;
    width: fit-content;
    min-width: 3rem;
    align-self: flex-start;
    padding: 0.5rem 0;
    max-height: 50rem;
    border: var(--border-thickness-normal) solid var(--color-card-border);
    background-color: var(--color-background-canvas);
    border-radius: var(--card-border-radius);
    z-index: var(--z-index-tooltip);
    box-shadow: var(--shadow-elevation-one);
  }
  .OptionsDropdown.visible {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .OptionsDropdown.hidden {
    display: none;
  }

  .OptionsDropdown.scroll {
    overflow-y: auto;
  }
  
  .OptionsRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    gap: 0.5rem;
    width: 100%;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    /* border-radius: var(--button-border-radius); */
    color: var(--gray-700);
  }
  .OptionsRow:hover {
    background-color: var(--color-background-primary);
    color: var(--color-input-normal);
    cursor: pointer;
  }

  .OptionsRow.selected {
    border-left: 0.25rem solid var(--color-input-normal);
  }

  .OptionsRowVerticalGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 20rem;
    gap: 0.25rem;
  }

  .OptionsRowDescription {
    font-size: var(--size-small); 
    font-weight: var(--weight-normal); ; 
    line-height: var(--size-small);
    color: var(--gray-500);
  }
  .OptionsRowText {
    white-space: nowrap;
    width: fit-content;
  }
  
  .OptionsRowIconContainer {
    display: flex;
    align-items: center;
  }
  .arrow-down {
    margin-left: auto;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity var(--transition-time);
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  