<template>
    <div class="LeftNav">
        <!-- Expand/ Collapse Editor -->
        <div class="StickyContainer BorderBottom NoHorizontalPadding">
            <div class="PanelToggleScaffold">
                <div class="PanelToggleContainer">
                  <themed-button componentId="editor_toggle" button-type="icon" icon="menu" :rotate=true
                  :tooltip-text = "TOOLTIP_EXPAND_EDITOR" align="left"
                  @buttonClick="handleButtonClick"/>
                </div>  
            </div>
        </div>
        <!-- Profile Section -->
        <div class="NavItem">
            <themed-button componentId="scroll_to_profile_card" button-type="icon" icon="phone"
                  :tooltip-text = "TOOLTIP_PROFILE" align="left"
                  color="var(--color-input-selected)"
                  @buttonClick="handleButtonClick"/>
        </div>
        <!-- Summary Section -->
        <div class="NavItem">
            <themed-button componentId="scroll_to_summary_card" button-type="icon" icon="summary"
                  :tooltip-text = "TOOLTIP_SUMMARY" align="left"
                  color="var(--color-input-selected)"
                  @buttonClick="handleButtonClick"/>
        </div>
        <!-- Education Section -->
        <div class="NavItem">
            <themed-button componentId="scroll_to_education_card" button-type="icon" icon="education"
                  :tooltip-text = "TOOLTIP_EDUCATION" align="left"
                  color="var(--color-input-selected)"
                  @buttonClick="handleButtonClick"/>
        </div>
        <!-- Experience Section -->
        <div class="NavItem" >
            <themed-button componentId="scroll_to_experience_card" button-type="icon" icon="experience"
                  :tooltip-text = "TOOLTIP_EXPERIENCE" align="left"
                  color="var(--color-input-selected)"
                  @buttonClick="handleButtonClick"/>
        </div>
        <!-- Other Sections -->
        <template v-for="section in sectionOrder" :key="section">
            <div class="NavItem" v-if="sections[section]?.visible || showOtherSections">
                <themed-button :componentId="'scroll_to_' + section" button-type="icon" :icon="section"
                  :tooltip-text = getTooltip(section) align="left"
                  color="var(--color-input-selected)"
                  @buttonClick="handleButtonClick"/>
            </div>
        </template>
        <!-- Add New Section -->
        <div class="PanelToggleScaffold BorderTop SeparatorMargin" v-if="!showOtherSections">
            <div class="PanelToggleContainer">
                <themed-button componentId="toggle_other_sections" button-type="icon" icon="add"
                :tooltip-text = "TOOLTIP_ADD_SECTION" align="left"
                @buttonClick="handleButtonClick"/>
            </div>  
        </div>
    </div>
</template>
  
<script>

import Icon from './Icon.vue';
import ThemedButton from './ThemedButton.vue';

  export default {
    name: 'ResumeLeftNav',
    emits: ['buttonClick'],
    props: {
        sections: Object,
        sectionOrder: Array,
        showOtherSections: Boolean
    },
    components: {
        Icon,
        ThemedButton
    },
    data() {
        return {
            TOOLTIP_EDIT: 'Edit ',
            TOOLTIP_EXPAND_EDITOR: 'Edit Resume',
            TOOLTIP_PROFILE: 'Edit Contact Information',
            TOOLTIP_SUMMARY: 'Edit Professional Summary',
            TOOLTIP_EDUCATION: 'Edit Education',
            TOOLTIP_EXPERIENCE: 'Edit Experience',
            TOOLTIP_ADD_SECTION: 'Add Resume Section'
        }
    },
    methods: {
        handleButtonClick (id) {
            console.log('Resume Left Nav: Clicked button ID: '+id);
            this.$emit('buttonClick', id);
        },
        getTooltip (section) {
            return (this.TOOLTIP_EDIT + this.sections[section]?.name)|| this.TOOLTIP_EXPAND_EDITOR;
        }
    }
  };
  </script>
  
  <style scoped>
  .PanelToggleScaffold {
      height: 5rem;
      width: 100%;
      display: flex;
  }
  .LeftNav {
    width: 4.25rem;
    height: 100vh;
    padding: 0 0.625rem;
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-background-canvas);
  }
  
  .NavItem {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-input-selected);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 2.875rem;
  }
  .NavItem:nth-child(2) {
    margin-top: 1.5625rem; /* This creates the 25px gap between the first and second items */
 }

  .NavItem:hover {
    color: var(--color-input-normal);
  }

  .SeparatorMargin {
    margin-top: 1.5625rem; /* This is the same gap as the first nav item */
  }
  </style>