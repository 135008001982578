<template>
    <div class="StepperGroup">
        <div class="StepperWithConnector" >
            <button class="StepWithIcon" :class="cursorClasses" @mouseover="handleMouseOver" @mouseout="handleMouseOut" @keydown="handleKeyDown" @click="handleClick"
             @blur="handleBlur" @focus="handleFocus" :aria-disabled=isDisabled :disabled=isDisabled role="button" :aria-labelledby="labelId">
                <div class="IconOutlineCheck" :class="buttonClasses" v-if="isComplete && !selected">
                    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 24 24">
                      <g fill="#76848d" class="nc-icon-wrapper">
                        <path d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12,12-5.373,12-12C23.981,5.381,18.619,.019,12,0Zm7.207,7.707l-9,9c-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-4-4c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l3.293,3.293L17.793,6.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Z" fill="currentColor"></path>
                      </g>
                    </svg>
                </div>
                <div class="CircleContainer" :class="buttonClasses"></div>
                <div class="InnerCircle" :class="buttonClasses" v-if="selected"></div>
              </button>
            <div class="JoiningLine" :class="connectorClasses" v-if="hasRightSibling"></div>
        </div>
        <div class="TextParagraph StepperText" :class="buttonClasses" v-if="text" :id="labelId">{{ text.toUpperCase() }}</div>
    </div>
</template>
  
<script>
  export default {
    name: 'stepper-button',
    emits: ['stepperClick'],
    props: {
      componentId: {
        type: String,
        required: true,
      },
      isComplete: { //whether the state has been isCompleted; if complete, it can't be disabled
        type: Boolean,
        default: false,
      },
      hasRightSibling: { //whether there is a step to the right
        type: Boolean,
        default: false,
      },
      isSelected: { //whether the step is currently selected; if active, it can't be disabled
        type: Boolean,
        default: false,
      },
      isDisabled: { //whether the step is currently disabled; if disabled, it can't be active
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
      }
    },
    computed: {
      labelId() { //generate an ID for the button label for accessibility
        return `step-${this.generateUniqueId()}`;
      },
      buttonClasses() {
        return {
          'completeNormal': this.isComplete && !this.selected,
          'incompleteNormal': !this.isComplete && !this.selected,
          'selected': this.selected,
          'disabled': this.isDisabled,
          'hover': this.isHoverState &&!this.isDisabled,
          'focus': this.isFocusState &&!this.isDisabled,
        };
      },
      connectorClasses() {
        return {
          'complete': this.isComplete,
          'incomplete': !this.isComplete,
        };
      },
      cursorClasses() {
        return {
          'enabled': !this.isDisabled && !this.selected,
        };
      },
    },
    data() {
      return {
        isHoverState: false,
        isFocusState: false,
        selected: this.isSelected,
      };
    },
    methods: {
      handleMouseOver() {
          this.isHoverState = true;
      },
      handleMouseOut() {
          this.isHoverState = false;
      },
      handleKeyDown(event) { //When a user enters a key on the button
          if (this.isDisabled) {
            return;
          }
          if (event.key === 'Enter' || event.key === ' ') {
            this.handleClick();
          }
      },
      handleFocus() { //when a user tabs in using a keyboard
        this.isFocusState = true;
      },
      handleBlur() { //when a user tabs out using a keyboard
        this.isFocusState = false;
      },
      handleClick() {
        this.$emit('stepperClick', this.componentId);
        this.selected = true;
      },
      generateUniqueId() {
      // Logic to generate a unique ID based on timestamp
      return Date.now();
      },
    },
  };
</script>
  
<style scoped>

  .CircleContainer {
    width: 2rem;
    height: 2rem;
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 9999px;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
  }
  
   /* Complete State + Not Selected */
   .CircleContainer.incompleteNormal {
    border: var(--border-thickness-selected) solid var(--color-outline-normal);
   }
   .CircleContainer.completeNormal{
    border: var(--border-thickness-selected) solid var(--success-500);
  }

  /* Selected State */
  .CircleContainer.selected {
    border: var(--border-thickness-selected) solid var(--color-input-selected);
  }
 
  .CircleContainer.hover {
    border: var(--border-thickness-selected) solid var(--color-input-normal);
    outline: var(--shadow-thickness) solid var(--color-background-primary);
  }
  .CircleContainer.focus {
    border: var(--border-thickness-selected) solid var(--color-input-selected);
    outline: var(--shadow-thickness) solid var(--color-outline-hover);
  }

  .CircleContainer.disabled {
    border:var(--border-thickness-selected) var(--color-outline-disabled) solid;
  }

  .InnerCircle {
    width:0.625rem;
    height:0.625rem;
    left:calc(0.625rem+var(--border-thickness-normal));
    top:calc(0.625rem+var(--border-thickness-normal));
    position:absolute;
    border-radius:9999px;
  }

  .InnerCircle.selected {
    background: var(--color-input-selected);
  }

  .JoiningLine {
    flex: 1 1 0;
    height: var(--border-thickness-selected);
    width: 5rem;
  }

  .JoiningLine.complete {
    background: var(--success-500);
  }
  .JoiningLine.incomplete {
    background: var(--color-outline-normal);
  }

  .StepWithIcon {
    /* Custom button style */
    width: 2rem;
    height: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    margin:0;
  }

  .StepWithIcon:focus {
  /* Override the default focus style of the button such that when a user tabs in/out of it, these don't apply */
  background-color: transparent; 
  border: none;
  outline: none;
}
.StepWithIcon.enabled {
    cursor: pointer;
  }

  .IconOutlineCheck {
    width: 2rem;
    height: 2rem;
    position: absolute;
  }
  .IconOutlineCheck.completeNormal{
    color: var(--success-500);
  }
  .IconOutlineCheck.hover{
    color: var(--color-input-normal);
  }
  .IconOutlineCheck.disabled {
    color: var(--color-outline-disabled);
  }

  .StepperWithConnector {
    height: 2rem;
    justify-content: flex-start; 
    align-items: center; 
    display: inline-flex
  }

  .StepperGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
  }

  .StepperText {
    text-wrap: nowrap;
    text-align: center;
    width: 6.25rem;
    left: -2.125rem;
  }

  .StepperText.incompleteNormal {
    color: var(--color-outline-normal);
  }
  .StepperText.completeNormal {
    color: var(--gray-700);
  }
  .StepperText.hover {
    color: var(--color-input-normal);
  }
  .StepperText.focus {
    color: var(--color-input-normal);
  }
  .StepperText.selected {
    color: var(--color-input-selected);
    font-weight: var(--weight-bold);
  }
  .StepperText.disabled {
    color: var(--color-outline-disabled);
  }

</style>
  